import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { MobilePrimaryMenu, MobileSecondaryMenu } from "./NavData";
import hamburgerMenu from "../../../assets/images/icons/hamburgerMenu.svg";
import exit from "../../../assets/images/icons/exit.svg";
import Logout from "../navigation/Logout";
import logout from "../../../assets/images/icons/logout.png";
import { AiTwotoneLock } from "react-icons/ai";

function MobileNav() {
  const isLockedFromPrimaryMenu = useSelector(
    (state) => state.userData.isLockedFromPrimaryMenu
  );
  const [hiddenMenu, setHiddenMenu] = useState(false);
  const showHiddenMenu = () => setHiddenMenu(!hiddenMenu);

  function disableLink(event) {
    event.preventDefault();
  }

  return (
    <div className="mobile-nav">
      <ul className="primary-menu">
        <li className="nav-item">
          <button className="link hamburger" onClick={showHiddenMenu}>
            <img src={hiddenMenu ? exit : hamburgerMenu} alt="menu" />
          </button>
        </li>
        {MobilePrimaryMenu.map((item, index) => {
          return (
            <li
              key={index}
              className={`nav-item ${
                isLockedFromPrimaryMenu && item.canBeLocked && "locked"
              }`}
            >
              <NavLink activeclassname="active" className="link" to={item.path}>
                {item.icon}
                {isLockedFromPrimaryMenu && item.canBeLocked ? (
                  <AiTwotoneLock size="12" />
                ) : null}
              </NavLink>
            </li>
          );
        })}
      </ul>

      {hiddenMenu ? <div className="overlay active"></div> : ""}
      <ul
        className={
          hiddenMenu ? "secondary-menu active m-0" : "secondary-menu m-0"
        }
      >
        {MobileSecondaryMenu.map((item, index) => {
          return (
            <li
              key={index}
              className={`nav-item ${
                isLockedFromPrimaryMenu && item.canBeLocked && "locked"
              }`}
              onClick={showHiddenMenu}
            >
              <NavLink
                activeclassname="active"
                className="link"
                to={item.path ? item.path : disableLink}
              >
                <span>
                  {item.icon}
                  {item.title}
                  {isLockedFromPrimaryMenu && item.canBeLocked ? (
                    <AiTwotoneLock size="12" />
                  ) : null}
                </span>
              </NavLink>
            </li>
          );
        })}
        <li className="nav-item">
          <div className="link">
            <span className="logout-wrapper">
              <img src={logout} alt="logout" />
              <Logout />
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default MobileNav;
