import Loading from "../reusableElementsComponents/Loading";
import { useSelector } from 'react-redux';

function NoDataHome() {
    const data = useSelector( state => state.contentfulData.all['home page no data']);


    if(data !== undefined) {

        let images = data.images;
        let content = data.fields;

        return (
            <>
                <img className="no-data-illustration" src={images.noDataHome} alt="No data" />
                <div className="header">
                    <h4 className="semi-bold header-text">{content.title}</h4>
                </div>
            </>
        )
    } else {
       return <Loading />
    }
}

export default NoDataHome;
