import { useEffect, useMemo } from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";

import Login from "./pages/auth/Login";
import RecoverPassword from "./pages/auth/RecoverPassword";
import RegistrationStage1 from "./pages/auth/RegistrationStage1";
//import RegistrationStage2 from "./pages/auth/RegistrationStage2";
import RegistrationStage3 from "./pages/auth/RegistrationStage3";
import Settings from "./pages/auth/Settings";
// import Coaching from "./pages/userPages/Coaching";
import FaqPage from "./pages/userPages/FaqPage";
import DataShareInvites from "./pages/userPages/DataShareInvites";
import TeamAnalytics from './pages/userPages/TeamAnalytics';
import Assessment from "./pages/userPages/Assessment";
// import HelpCenter from './pages/userPages/HelpCenter';
import ErrorPage from "./pages/errorPage/ErrorPage";
import Dashboard from "./pages/userPages/Dashboard";
import SetPassword from "./pages/userPages/SetPassword";
import Sidebar from "./components/layoutComponents/navigation/Sidebar";
import MobileNav from "./components/layoutComponents/navigation/MobileNav";
import * as Constants from "./constants";

import { fetchContentfulData } from "./store/actions/contentfulDataActions";
import { fetchContentfulTalentAppData } from "./store/actions/contentfulTalentAppDataActions";
import {
  setTeamData,
  setRequiredAssessments,
} from "./store/actions/teamDataActions";

import {
  setPoolData,
  setPoolRequiredAssessments,
} from "./store/actions/poolDataActions";

import { setUserData } from "./store/actions/userDataActions";
import { useDispatch, useSelector } from "react-redux";

import { ThemeProvider } from "@mui/material";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";

import "react-toastify/dist/ReactToastify.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import TalentsApiService from "./TalentsApiService";
import * as Utilities from "./Utilities";
import { MUITheme } from "./MUITheme";

import { logPageView } from "./analytics";

function App() {
  // VARIABLES AND STATES DECLARATION
  const userCompletedAssessments = useSelector(
    (state) => state.userData?.all?.assessment_report_list
  );
  const teamRequiredAssessments = useSelector(
    (state) => state.teamData?.requiredAssessments
  );
  const poolRequiredAssessments = useSelector(
      (state) => state.poolData?.requiredAssessments
  );
  const allAssessmentsCompleted = useSelector(
    (state) => state.userData?.allAssessmentsCompleted
  );

  const dispatch = useDispatch();

  const isAuthenticated =
    localStorage.getItem(Constants.MAZHR_JWT_KEY) !== null;

  const isProduction = process.env.NODE_ENV === "production";

  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);

  /**
   * GET URL PARAMS AND CHECK IF USER HAS INVITATION TO JOIN A TEAM
   * IF YES, SET hasInvitation TO TRUE AND DISPATCH invitationData TO REDUX STORE
   * BASED ON hasInvitation, WE WILL RENDER DIFFERENT ROUTES
   * */
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let hasInvitation = false;

  const invitationTeamHash = urlParams?.team;
  const invitationUserToken = urlParams?.["user-token"];
  if (invitationTeamHash && invitationUserToken) {
    hasInvitation = true;
    dispatch(
      setUserData({
        invitationData: urlParams,
      })
    );
  }

  const invitationPoolHash = urlParams.hash;
  if(invitationPoolHash){
    hasInvitation = true;
    dispatch(
        setUserData({
          invitationData: urlParams,
        })
    );
  }

  // FETCH CONTENTFUL DATA
  useEffect(() => {
    logPageView();
    dispatch(fetchContentfulData());
    dispatch(fetchContentfulTalentAppData());
    // eslint-disable-next-line
  }, []); // We only want to fetch this data once on page load

  /**
   * CHECK IF USER IS AUTHENTICATED AND FETCH USER DETAILS AND TEAM DETAILS
   * IF USER IS AUTHENTICATED, CHECK IF USER IS INVITED TO JOIN A TEAM VIA URL PARAMS. IF YES, FETCH USER AND TEAM DETAILS
   */
  useEffect(() => {
    // Fetch user details if authenticated and save to redux store
    if (isAuthenticated) {
      talentsServiceObj.GetCurrentUserDetails((json) => {
        dispatch(setUserData({ all: json }));
        // Fetch team details if user is part of one or more teams and save to redux store
        if (json?.linked_teams && json?.linked_teams.length > 0) {
          json.linked_teams.forEach((team) => {
            talentsServiceObj.LoadTeamByHash(team.hash, (teamJson) => {
              dispatch(setTeamData(teamJson));
              if(teamJson?.team?.invite_accepted){
                dispatch(
                    setRequiredAssessments(teamJson?.team?.required_assessments)
                );
              }
            });
          });
        }

        if (json?.linked_pools && json?.linked_pools.length > 0) {
          json.linked_pools.forEach((pool) => {
            talentsServiceObj.LoadPoolByHash(pool.hash, (poolJson) => {
              dispatch(setPoolData(poolJson));
              if(poolJson?.pool?.invite_accepted){
                dispatch(
                    setPoolRequiredAssessments(poolJson?.pool?.required_assessments)
                );
              }
            });
          });
        }
      });
    } else {
      // Check if user is invited to join a team via URL params and save to redux store
      if (invitationTeamHash) {
        // Fetch team details if user is invited to join a team and save to redux store
        talentsServiceObj.LoadTeamByHash(invitationTeamHash, (teamJson) => {
          dispatch(setTeamData(teamJson));
          dispatch(
            setRequiredAssessments(teamJson?.team?.required_assessments)
          );
        });
      }
    }
  }, [isAuthenticated, talentsServiceObj, invitationTeamHash, dispatch]);

  /**
   * GET TEAM REQUIRED ASSESSMENTS AND CHECK IF USER HAS COMPLETED ALL REQUIRED ASSESSMENTS
   * IF YES, SHOW USER FULL PRIMARY MENU
   * IF NO, LOCK USER FROM PRIMARY MENU AND SHOW ONLY ASSESSMENT ROUTES
   */
  useEffect(() => {
    // Lock user from primary menu if user is invited to join a team and has not completed required assessments
    if (userCompletedAssessments) {
      if (Array.isArray(userCompletedAssessments)) {
        //checking for team
        if (
            teamRequiredAssessments &&
            Array.isArray(teamRequiredAssessments) &&
            teamRequiredAssessments?.length > 0) {
          if (
              Utilities.hasUserCompletedAllRequiredAssessments(
                  teamRequiredAssessments,
                  userCompletedAssessments
              )
          ) {
            dispatch(
                setUserData({
                  isLockedFromPrimaryMenu: false,
                  allAssessmentsCompleted: true,
                })
            );
          } else {
            dispatch(
                setUserData({
                  isLockedFromPrimaryMenu: true,
                  allAssessmentsCompleted: false,
                })
            );
          }
        }

        if (
            poolRequiredAssessments &&
            Array.isArray(poolRequiredAssessments) &&
            poolRequiredAssessments?.length > 0
        ) {
          if (
              Utilities.hasUserCompletedAllRequiredAssessments(
                  poolRequiredAssessments,
                  userCompletedAssessments
              )
          ) {
            dispatch(
                setUserData({
                  isLockedFromPrimaryMenu: false,
                  allAssessmentsCompleted: true,
                })
            );
          }
        }
      } else {
        dispatch(
            setUserData({
              isLockedFromPrimaryMenu: false,
              allAssessmentsCompleted: true,
            })
        );
      }
    }
  }, [poolRequiredAssessments, teamRequiredAssessments, userCompletedAssessments, dispatch]);

  // RENDER MAIN COMPONENT
  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <ThemeProvider theme={MUITheme}>
        <div className="App">
          <div className="page">
            {isAuthenticated &&
              location.pathname !== "/recover-password" &&
              location.pathname !== "/terms-conditions" && (
                <>{window.innerWidth > 992 ? <Sidebar /> : <MobileNav />}</>
              )}

            <Routes>
              {/**
               * ROUTES FOR UNAUTHENTICATED USERS WITH INVITATION
               */}
              {
                /**
                 * This is only for team members who are not yet registered, but received an invitation link with the below path construction
                 * Already registered team members will login normally and redirected to dashboard or onboarding depending on their status
                 */
                !isAuthenticated && hasInvitation && (
                  <Route path="/" element={<RegistrationStage1 />} />
                )
              }
              {/**
               * ROUTES FOR ALL UNAUTHENTICATED USERS
               */}
              {!isAuthenticated && (
                <>
                  <Route path="login" element={<Login />} />
                  {/* TEMPORARILY DISABLE REGISTRATION FOR ANONYMOUS USER WITHOUT TEAM OR POOL INVITATION */}
                  {/* <Route path="register" element={<RegistrationStage1 />} /> */}
                  {/* <Route
                    path="talent-details-1"
                    element={<RegistrationStage2 />}
                  /> */}
                  <Route
                    path="recover-password"
                    element={<RecoverPassword />}
                  />
                  <Route path="set-password" element={<SetPassword />} />
                  <Route path="*" element={<Navigate to="login" />} />
                </>
              )}

              {/**
               * ROUTES FOR AUTHENTICATED USERS WITH TEAM/POOL INVITATION
               */}
              {isAuthenticated && !allAssessmentsCompleted && (
                <>
                  <Route path="*" element={<Navigate to="assessment" />} />
                </>
              )}

              {/**
               * ROUTES FOR AUTHENTICATED USERS WITHOUT INVITATION
               */}
              {isAuthenticated && allAssessmentsCompleted && (
                <>
                  <Route path="*" element={<Dashboard />} />
                  <Route path="/" element={<Dashboard />} />
                  {/* Coaching IS NOT YET AVAILABLE */}
                  {/* <Route path="coaching" element={<Coaching />} /> */}
                  <Route path="team-analytics" element={<TeamAnalytics/>}/>
                </>
              )}

              {/**
               * ROUTES FOR ALL AUTHENTICATED USERS
               */}
              {isAuthenticated && (
                <>
                  <Route path="faq-page" element={<FaqPage />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="assessment" element={<RegistrationStage3 />} />
                  <Route
                    path="data-share-invites"
                    element={<DataShareInvites />}
                  />
                  <Route
                    path="assessment/:assessmentPathName"
                    element={<Assessment />}
                  />
                </>
              )}
              {/**
               * ROUTES FOR ALL OTHER USERS
               */}
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>
        </div>
      </ThemeProvider>
    </CacheBuster>
  );
}

export default App;
