import TalentsApi from '../../../TalentsApi';
import { useNavigate } from "react-router-dom";
import {useSelector} from 'react-redux';
import Loading from '../../../components/reusableElementsComponents/Loading';

function Logout() {
    const navigate = useNavigate();

    const data = useSelector(state => state.contentfulData.all['Navigation']);

    function logout() {
        let obj = new TalentsApi();
        obj.logout();
        navigate("/");
        window.location.reload();
    };

    if (data !== undefined) {
        let content = data.fields;

        return (
            <span className="logout" onClick={logout}>{content.logout}</span>
        )
    }else {
        return <Loading/>
    }
}

export default Logout
