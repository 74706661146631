import * as Constants from './constants'
import axios from 'axios'
import {store} from './store/store';
import {setCompanyStatus} from './store/actions/companyActions'

export default class TalentsApi {

    INVALID_TOKEN_HTTP_CODES = [
        401,
        422,
        407,
        429,
        451,
        503,
        511
    ]
    SetUserData = (token) => {
        localStorage.setItem(Constants.MAZHR_USER_DATA_KEY, token);
    }

    GetUserData = () => {
        return localStorage.getItem(Constants.MAZHR_USER_DATA_KEY) == null ? false : JSON.parse(localStorage.getItem(Constants.MAZHR_USER_DATA_KEY));
    }

    GetJWT = () => {
        return localStorage.getItem(Constants.MAZHR_JWT_KEY);
    }

    refreshToken = () =>{
        if(this.GetJWT()?.refresh_token){
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(this.GetJWT()).refresh_token;
            axios.post(Constants.API_URL + 'auth/refresh-token/').then((res) => {
                if(res.status === 200){
                    let jwtData = this.GetJWT();
                    jwtData.token = res.data.token;
                    this.SetUserData(jwtData)
                }else{
                    localStorage.clear();
                    window.location.reload()
                }
            })
        }else{
            localStorage.clear();
            window.location.reload()
        }

    }

    logout = () => {
        localStorage.clear();
        if (this.GetJWT() && JSON.parse(this.GetJWT()).access_token) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(this.GetJWT()).access_token;
            axios.post(Constants.API_URL + 'auth/signout/').then((res) => {
                if(res.status === 200){
                    localStorage.clear();
                }else{
                    this.refreshToken();
                }
            })

        } else {
            localStorage.clear();
        }
        window.location.reload()
    }

    apiCall = async (endpoint, method, jsonBody) => {
        let url = Constants.API_URL + endpoint,
            response;

        let headers = {};

        if (this.GetJWT() && JSON.parse(this.GetJWT()).access_token) {
            headers['Authorization'] = 'Bearer ' + JSON.parse(this.GetJWT()).access_token;
        }
        headers['Accept'] = 'application/json, text/javascript, */*';
        headers['Content-Type'] = 'application/json';

        let errorMessage = '';
        try {
            response = await fetch(url, {
                headers: headers,
                method: method.toLowerCase(),
                body: jsonBody
            });
        } catch (e) {
            errorMessage = e.message;
        }

        if (endpoint !== 'auth/login/' && response && this.INVALID_TOKEN_HTTP_CODES.includes(response.status)) {
            if(response.status === 401 ||  response.status === 422){
                this.refreshToken()
            }else{
                this.logout()
            }
        }

        if (response === undefined || response.status === 500) {
            return {
                ok: false,
                httpStatus: 0,
                httpReason: '',
                error: 'disconnected',
                json: null,
            };
        }else if(response.status === 204){
            return {
                ok: false,
                httpStatus: 204,
                httpReason: '',
                json: null,
            };
        }else {
            let result = {
                ok: response.status && errorMessage === '',
                httpStatus: response.status,
                httpReason: response.statusText,
                error: errorMessage,
                json: await response.json(),
            };

            if(result.httpStatus === 202){
                if(!result.json?.status && result.json?.error){
                    //check error code for inactive company
                    if(result.json.error.code === 1004){
                        store.dispatch(setCompanyStatus(false))
                    }
                }
            }

            return result;
        }
    }
}
