import axios from "axios";
import { ActionTypes } from "../constants/action-types";
import * as Constants from "../../constants";

export function fetchContentfulTalentAppData() {
  const request = axios.get(
    `${Constants.CONTENTFUL_API_BASE_URL}/spaces/${Constants.CONTENTFUL_API_SPACE_ID}/environments/development/entries?access_token=${Constants.CONTENTFUL_API_TOKEN}&sys.contentType.sys.id[in]=singlePageContent,assessment&include=2`
  );
  return {
    type: ActionTypes.FETCH_CONTENTFUL_TALENT_APP_DATA,
    payload: request,
  };
}
