import TalentsApi from "../../TalentsApi";
import { useSelector } from "react-redux";
import * as Utilities from "../../Utilities";
import Loading from "../../components/reusableElementsComponents/Loading";
import Form from "../../components/reusableElementsComponents/Form";
import Button from "../../components/reusableElementsComponents/Button";
import lock from "../../assets/images/icons/lock.svg";
import { ToastContainer, toast } from "react-toastify";

// This content placeholder is to prevent the app from crashing if the content is not fetched from Contentful
const content_placeholder = {
  password: "Password",
  passwordInputPlaceholder: "Enter existing password",
  newPassword: "New password",
  newPasswordInputPlaceholder: "Enter new password",
  confirmNewPassword: "Confirm new password",
  confirmNewPasswordInputPlaceholder: "Confirm new password",
  saveChanges: "Save changes",
  passwordPolicy:
    "Password must contain at least 8 characters, a capital letter, a number and a special symbol",
  toastMessageUpdate: "Updating password...",
  toastMessageSuccess: "Password updated successfully",
  toastMessageErrorSamePassword: "Password must be same",
  toastMessageErrorInvalidCurrentPassword: "Invalid current password",
  toastMessageErrorInvalidValues: "Invalid values provided",
};

function ChangePassword() {
  const pageData = useSelector(
    (state) => state.contentfulData.all["change password"]
  );

  function onSubmit(values) {
    let obj = new TalentsApi();
    if (values.new_password !== values.confirm_password) {
      toast.error(
        pageData?.fields?.toastMessageErrorSamePassword ||
          content_placeholder.toastMessageErrorSamePassword,
        {
          hideProgressBar: true,
          closeOnClick: true,
        }
      );
      return;
    }
    const updatePasswordToast = toast.loading(
      pageData?.fields?.toastMessageUpdate ||
        content_placeholder.toastMessageUpdate
    );
    obj
      .apiCall(
        "user/password/",
        "post",
        JSON.stringify({
          password: values.password,
          new_password: values.new_password,
        })
      )
      .then((data) => {
        if (data.httpStatus === 200) {
          if (data && !data.json) {
            if (data.error && data.error.code === 1002) {
              toast.update(updatePasswordToast, {
                render:
                  pageData?.fields?.toastMessageErrorInvalidCurrentPassword ||
                  content_placeholder.toastMessageErrorInvalidCurrentPassword,
                type: "error",
                isLoading: false,
                autoClose: "2000",
                closeButton: true,
                hideProgressBar: true,
              });
            }
          } else if (data.json.status) {
            toast.update(updatePasswordToast, {
              render:
                pageData?.fields?.toastMessageSuccess ||
                content_placeholder.toastMessageSuccess,
              type: "success",
              isLoading: false,
              autoClose: "2000",
              closeButton: true,
              hideProgressBar: true,
            });
          }
        } else {
          toast.update(updatePasswordToast, {
            render:
              pageData?.fields?.toastMessageErrorInvalidValues ||
              content_placeholder.toastMessageErrorInvalidValues,
            type: "error",
            isLoading: false,
            autoClose: "2000",
            closeButton: true,
            hideProgressBar: true,
          });
        }
      });
  }

  function validate(watchValues, errorMethods) {
    let { errors, setError, clearErrors } = errorMethods;

    // Password validation
    let validPassword =
      /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/;

    if (
      watchValues["password"] &&
      !validPassword.test(watchValues["password"])
    ) {
      if (!errors["password"]) {
        setError("password", {
          type: "manual",
          message:
            pageData?.fields?.passwordPolicy ||
            content_placeholder.passwordPolicy,
        });
      }
    } else {
      if (errors["password"] && errors["password"]["type"] === "manual") {
        clearErrors("password");
      }
    }
  }

  if (!Utilities.isEmptyObject(pageData?.fields)) {
    let template = {
      fields: [
        {
          title: pageData?.fields?.password || content_placeholder.password,
          type: "password",
          name: "password",
          placeholder:
            pageData?.fields?.passwordInputPlaceholder ||
            content_placeholder.passwordInputPlaceholder,
          icon: lock,
        },
        {
          title:
            pageData?.fields?.newPassword || content_placeholder.newPassword,
          type: "password",
          name: "new_password",
          placeholder:
            pageData?.fields?.newPasswordInputPlaceholder ||
            content_placeholder.newPasswordInputPlaceholder,
          icon: lock,
        },
        {
          title:
            pageData?.fields?.confirmNewPassword ||
            content_placeholder.confirmNewPassword,
          type: "password",
          name: "confirm_password",
          placeholder:
            pageData?.fields?.confirmNewPasswordInputPlaceholder ||
            content_placeholder.confirmNewPasswordInputPlaceholder,
          icon: lock,
        },
      ],
    };

    return (
      <div className="change-password-inner-div">
        <ToastContainer />
        <Form
          template={template}
          watchFields={["password"]}
          validate={validate}
          onSubmit={onSubmit}
        >
          <Button
            type="submit"
            btnstyle={"mazhrBtn default-green white"}
            onSubmit={onSubmit}
          >
            {pageData?.fields?.saveChanges || content_placeholder.saveChanges}
          </Button>
        </Form>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default ChangePassword;
