import {ActionTypes} from "../constants/action-types";

export function setPoolData(data){
    return {
        type: ActionTypes.SET_POOL_POSITION_DATA,
        payload: data
    };
}


export function setPoolRequiredAssessments(data) {
    return {
        type: ActionTypes.SET_POOL_REQUIRED_ASSESSMENTS,
        payload: data,
    };
}
