import { useState, useEffect } from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import InputError from "./InputError";

import InputAdornment from "@mui/material/InputAdornment";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export const InputIcon = () => {
  return (
    <TextField
      id="input-with-icon-textfield"
      label="TextField"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <AttachMoneyIcon />
          </InputAdornment>
        ),
      }}
      variant="standard"
    />
  );
};

export const SelectFullWidth = ({
  label,
  options,
  doValidation,
  errorContent,
  handleChange,
  children,
  option,
  disabled,
}) => {
  return (
    <Box width="100%" sx={{ fontFamily: "Raleway" }}>
      {children}
      <TextField
        id="standard-basic"
        variant="standard"
        label={label}
        defaultValue={option}
        select
        value={option}
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        color="primary"
      >
        {options &&
          options.map((obj, index) => {
            if (!option || option === "") {
              option = obj.title;
            }
            return (
              <MenuItem key={index} value={obj.title}>
                {obj.title}
              </MenuItem>
            );
          })}
      </TextField>

      {doValidation && option.length < 1 ? (
        <InputError errors={doValidation} errorMessage={errorContent} />
      ) : (
        ""
      )}
    </Box>
  );
};

export const Select = ({
  label,
  options,
  doValidation,
  errorContent,
  handleChange,
  children,
  option,
  disabled,
  defaultValue = null,
}) => {
  const [value, setValue] = useState(option ? option : "");
  const onChange = (e) => {
    setValue(e.target.value);
    handleChange(e);
  };
  useEffect(() => {
    setValue(option ? option : "");
  }, [option]);
  return (
    <Box width="250px" sx={{ fontFamily: "Raleway" }}>
      {children}
      <TextField
        label={label}
        value={value}
        select={true}
        style={{ backgroundColor: "#ffffff" }}
        onChange={onChange}
        fullWidth
        disabled={disabled}
        size="small"
        color="primary"
        inputProps={{ style: { fontSize: "14px", paddingTop: "14px" } }} // font size of input text
        InputLabelProps={{ style: { fontSize: "14px" } }} // font size of input label
      >
        {defaultValue && <MenuItem value="">{defaultValue}</MenuItem>}
        {options &&
          options.map((obj, index) => {
            return (
              <MenuItem key={index} value={obj.title}>
                {obj.title}
              </MenuItem>
            );
          })}
      </TextField>

      {doValidation && option.length < 1 ? (
        <InputError errors={doValidation} errorMessage={errorContent} />
      ) : (
        ""
      )}
    </Box>
  );
};

export const SalesSelect = ({
  label,
  options,
  doValidation,
  errorContent,
  handleChange,
  children,
  option,
  disabled,
}) => {
  return (
    <Box width="250px" sx={{ fontFamily: "Raleway" }}>
      {children}
      <TextField
        label={label}
        value={option}
        select={true}
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        size="small"
        color="primary"
        inputProps={{ style: { fontSize: "14px" } }} // font size of input text
        InputLabelProps={{ style: { fontSize: "14px" } }} // font size of input label
      >
        {options &&
          options.map((obj, index) => {
            if (!option || option === "") {
              option = obj.title;
            }
            return (
              <MenuItem key={index} value={obj.title}>
                {obj.title}
              </MenuItem>
            );
          })}
      </TextField>

      {doValidation && option.length < 1 ? (
        <InputError errors={doValidation} errorMessage={errorContent} />
      ) : (
        ""
      )}
    </Box>
  );
};

export const Text = ({
  title,
  required,
  fullWidth,
  doValidation,
  errorContent,
  handleChange,
  value,
}) => {
  let [textVal, setTextVal] = useState(value ? value : "");

  const validateValue = (e) => {
    setTextVal(e.target.value);
    handleChange(e);
  };

  return (
    <>
      <TextField
        id="standard-basic"
        onChange={validateValue}
        label={title}
        variant="standard"
        multiline
        fullWidth={fullWidth}
        required={required}
        value={value}
        InputLabelProps={{ shrink: true }}
      />
      {doValidation && textVal.length < 1 ? (
        <InputError errors={doValidation} errorMessage={errorContent} />
      ) : (
        ""
      )}
    </>
  );
};

export const TextArea = ({
  title,
  required,
  fullWidth,
  doValidation,
  errorContent,
  handleChange,
  value,
}) => {
  const [textVal, setTextVal] = useState("");
  const validateValue = (e) => {
    setTextVal(e.target.value);
    handleChange(e);
  };
  return (
    <>
      <TextField
        id="standard-basic"
        onChange={validateValue}
        label={title}
        variant="outlined"
        multiline
        fullWidth={fullWidth}
        required={required}
        minRows={5}
        value={value}
        InputLabelProps={{ shrink: true }}
      />
      {doValidation && textVal.length < 1 ? (
        <InputError errors={doValidation} errorMessage={errorContent} />
      ) : (
        ""
      )}
    </>
  );
};
