import { useState} from "react";
import { useSelector } from 'react-redux';
import { BsFillCloudUploadFill } from "react-icons/bs";
import { ImBin } from "react-icons/im";
import TalentsApi from "../../TalentsApi";


function UploadImage({ name, placeholder, defaultValue, updatingUploadImageState}) {
    let obj = new TalentsApi();
    const companyData = useSelector(state => state.company.info);
    const [image, setImage] = useState(defaultValue);
    const [isUploaded, setIsUploaded] = useState(false);



    function handleImageChange(e) {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader()

            reader.onload = function (e) {
                setImage(e.target.result);
                setIsUploaded(true)
                updatingUploadImageState(isUploaded)
            }
            reader.readAsDataURL(e.target.files[0])
        }
    }

    return (
        <div className="image-display">
            <div className="img">
                {(defaultValue || isUploaded) ? <img id="comapnyLogo" src={image} alt="company logo" /> : <img src={placeholder} alt="company logo" />}
                <div className="controls">
                    <input
                        style={{ display: "none" }}
                        type="file"
                        name={name}
                        id={name}
                        accept="image/*"
                        onChange={e => {
                            handleImageChange(e);
                        }}
                    />
                    <label htmlFor={name}>
                        <div className="label-content">
                            <BsFillCloudUploadFill size={60} color="#00B0A0" />
                        </div>
                    </label>
                </div>
            </div>
            <div className="remove-image">
                <ImBin size={20} color="#C13F8A" onClick={handleDelete} />
            </div>
        </div>
    )

    function handleDelete() {
        let val = companyData.industry.id
         val = (val).toString()
            obj.apiCall('company/info/', 'put', JSON.stringify({
                "delete_logo": true,
                "company_name": companyData.name,
                "contact_phone": companyData.contact_phone,
                "employee_count": companyData.employee_count,
                "industry_id": val,
                "logo": companyData.logo
            })).then((res) => {
                if(res.httpStatus === 200){
                    window.location.reload();
                }

            })
        ;
    }
}

export default UploadImage;

