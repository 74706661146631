import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import PageLayout from "../../components/layoutComponents/PageLayout";
import MazhrModal from "../../components/reusableElementsComponents/MazhrModal";
import Loading from "../../components/reusableElementsComponents/Loading";

import Switch from "@mui/material/Switch";
import checkGreen from "../../assets/images/icons/checkGreen.svg";
import lockIcon from "../../assets/images/icons/lock.svg";

import TalentsApiService from "../../TalentsApiService";
import * as Utilities from "../../Utilities";

function DataShareInvites() {
  const [teamData, setTeamData] = useState([]);
  const [expandedTeamIndex, setExpandedTeamIndex] = useState(0);
  const [chosenTeamId, setChosenTeamId] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogRejectReasons, setDialogRejectReasons] = useState(null);

  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);

  const pageData = useSelector((state) =>
    state.contentfulData.talentAppData
      ? state.contentfulData.talentAppData?.["messages"]?.fields
      : null
  );

  useEffect(() => {
    talentsServiceObj.FetchDataShareInvites(setTeamData);
    setDialogRejectReasons(
      pageData?.dialog?.reject_reasons?.map((i) => ({
        ...i,
        isSelected: false,
      })) || null
    );
    // eslint-disable-next-line
  }, []); // Only run this once at the beginning

  /**
   * ALL ACTION HANDLERS
   */
  const handleRowClick = (e, index) => {
    e.stopPropagation();
    setExpandedTeamIndex(index);
  };

  const handleToggleClick = (e, teamId, currentDataAccess) => {
    e.stopPropagation();
    setOpenDialog(true);
    setDialogContent(
      currentDataAccess ? pageData.dialog.reject : pageData.dialog.accept
    );
    setChosenTeamId(teamId);
  };

  const handleDialogCheckboxChange = (e, index) => {
    e.stopPropagation();
    const newDialogRejectReasons = dialogRejectReasons.map((label, i) => {
      if (i === index) {
        return {
          ...label,
          isSelected: !label.isSelected,
        };
      }
      return label;
    });
    setDialogRejectReasons(newDialogRejectReasons);
  };

  const handleInvitationAction = (e, isAccepted) => {
    e.stopPropagation();
    setTeamData((prevData) => {
      return prevData.map((team) => {
        if (team.id === chosenTeamId) {
          return {
            ...team,
            dataAccess: isAccepted,
          };
        }
        return team;
      });
    });
    setOpenDialog(false);
    setDialogRejectReasons(
      dialogRejectReasons.map((label) => ({ ...label, isSelected: false }))
    ); // Reset the checkboxes
  };

  /**
   * ALL RENDER FUNCTIONS
   */
  const renderTeamContent = (team, content) => {
    if (!team) return null;
    return (
      <div className="high-light">
        {team.team ? (
          <p>
            {content?.areYouEmployedBy || "Are you employed by"}{" "}
            <span className="semi-bold">{team.company}</span>{" "}
            {content?.andWorkInTeam || "and work in team"}{" "}
            <span className="semi-bold">{team.team}</span>?
          </p>
        ) : team.job ? (
          <p>
            {content?.areYouApplyingToJob || "Are you applying to job"}{" "}
            <span className="semi-bold">{team.job}</span>{" "}
            {content?.inCompany || "in company"}{" "}
            <span className="semi-bold">{team.company}</span>?
          </p>
        ) : (
          ""
        )}

        <p className="semi-bold">
          {content?.requestedAssessments || "Requested assessments"}:
        </p>
        <ul>
          {team?.requested_assessments.map((assessment, index) => (
            <li key={index} className="high-light-list-item">
              {assessment.completed ? (
                <img src={checkGreen} alt="messages-page-assessment-check" />
              ) : (
                <img src={lockIcon} alt="messages-page-assessment-lock" />
              )}

              {assessment.label}
            </li>
          ))}
        </ul>
        <p>
          <span className="semi-bold">{content?.about || "About"}: </span>
          {team?.about}
        </p>
        <p></p>
      </div>
    );
  };

  if (pageData && !Utilities.isEmptyObject(pageData)) {
    return (
      <div className="bg-cover">
        <PageLayout>
          <div className="messages-page-wrapper">
            <h1>{pageData.titleDesktop || ""}</h1>
          </div>
        </PageLayout>

        <PageLayout>
          <div className="messages-page-wrapper">
            <div className="messages-page-section team-list">
              <div className="team-list-row team-list-head">
                <div>{pageData.invitation || ""}</div>
                <div className="team-list-filter">{pageData.status || ""}</div>
                <div className="team-list-filter">
                  {pageData.dataAccess || ""}
                </div>
              </div>
              <div>
                {teamData.map((team, index) => (
                  <React.Fragment key={index}>
                    <div
                      onClick={(e) => handleRowClick(e, index)}
                      className={`team-list-row team-list-body ${
                        expandedTeamIndex === index ? "expanded" : ""
                      } ${expandedTeamIndex === index ? "desktop-focus" : ""}`}
                    >
                      <div>
                        <p>
                          {team.team
                            ? `${pageData.team}: ${team.team}`
                            : team.job
                            ? `${pageData.job}: ${team.job}`
                            : ""}
                        </p>
                        <p>{team.company}</p>
                      </div>
                      <div className="team-list-filter">
                        {pageData.statusOption[team.status] || ""}
                      </div>
                      <div className="team-list-filter">
                        <Switch
                          checked={team.dataAccess ? true : false}
                          onChange={(e) =>
                            handleToggleClick(e, team.id, team.dataAccess)
                          }
                          inputProps={{ "aria-label": "condivolled" }}
                        />
                      </div>
                    </div>
                    {index === expandedTeamIndex && (
                      <div className="expanded-row">
                        <div colSpan="4">
                          {renderTeamContent(team, pageData)}
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="messages-page-section about-team">
              <div className="team-list-row team-list-head"></div>
              {teamData &&
                teamData.length > 0 &&
                renderTeamContent(teamData[expandedTeamIndex], pageData)}
            </div>
          </div>
        </PageLayout>

        <MazhrModal
          show={openDialog}
          onHide={() => setOpenDialog(false)}
          header={<h5 className="">{dialogContent && dialogContent.header}</h5>}
        >
          {dialogContent && (
            <div className="messages-page-dialog-wrapper">
              <p className="">{dialogContent.content}</p>
              <div className="">
                {
                  // If the dialog is for rejection, show the checkboxes
                  !dialogContent.isAccepted &&
                    dialogRejectReasons.map((label, index) => (
                      <label
                        htmlFor={`messagesPageDialogLabel${index}`}
                        className="dialog-list-item"
                      >
                        <input
                          type="checkbox"
                          name={label.id}
                          checked={label.isSelected}
                          onChange={(e) => handleDialogCheckboxChange(e, index)}
                          className="list-item-checkbox"
                        />
                        <span>{label.label}</span>
                      </label>
                    ))
                }
              </div>
              <button
                className={`mazhrBtn w-100 dialog-button ${
                  dialogContent.isAccepted ? "accept" : "reject"
                }`}
                onClick={(e) =>
                  handleInvitationAction(e, dialogContent.isAccepted)
                }
              >
                {dialogContent.buttonLabel}
              </button>
            </div>
          )}
        </MazhrModal>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default DataShareInvites;
