import greenLogoNoText from "../../../assets/images/logos/greenLogoNoText.svg";
import groupOfPeople from "../../../assets/images/icons/groupOfPeople.svg";
import suitcase from "../../../assets/images/icons/suitcase.svg";
import question from "../../../assets/images/icons/question.svg";
import cog from "../../../assets/images/icons/cog.svg";
import ShareIcon from "@mui/icons-material/Share";
import Diversity3Icon from '@mui/icons-material/Diversity3';

export const PrimaryMenu = [
  {
    enabled: true,
    canBeLocked: true, // The menu item can be locked if redux state.userData.isLockedFromPrimaryMenu is true
    title: "Dashboard",
    icon: <img className="navIcon" src={groupOfPeople} alt="dashboard" />,
    submenu: [
      {
        title: "Dashboard",
        path: "/",
      },
    ],
  },
  {
    enabled: true,
    canBeLocked: false, // The menu item cannot be locked even if redux state.userData.isLockedFromPrimaryMenu is true
    title: "Assessments",
    icon: <img className="navIcon" src={suitcase} alt="assessments" />,
    submenu: [
      {
        title: "Assessments",
        path: "/assessment",
      },
      // {
      //   title: "Insights & Reports",
      //   path: "/candidate-analytics",
      // },
    ],
  },
  {
    enabled: true,
    canBeLocked: false, // The menu item cannot be locked even if redux state.userData.isLockedFromPrimaryMenu is true
    title: "Data Share Invites",
    path: "/data-share-invites",
    icon: <ShareIcon className="navIcon" />,
    submenu: [
      {
        title: "Data Share Invites",
        path: "/data-share-invites",
      },
    ],
  },
  {
    enabled: true,
    title: "Team",
    path: "/team-analytics",
    icon: <Diversity3Icon className="navIcon" />,
    submenu: [
      {
        title: "Team Analytics",
        path: "/team-analytics",
      },
    ],
  },
];
export const SecondaryMenu = [
  {
    enabled: true,
    title: "Help Center",
    path: "/faq-page",
    icon: <img className="navIcon" src={question} alt="FAQ" />,
  },
  {
    enabled: true,
    title: "Settings",
    path: "/settings",
    icon: <img className="navIcon" src={cog} alt="settings" />,
  },
];

export const MobilePrimaryMenu = [
  {
    title: "Dashboard",
    path: "/",
    icon: <img className="navIcon" src={groupOfPeople} alt="dashboard" />,
    canBeLocked: true, // The menu item can be locked if redux state.userData.isLockedFromPrimaryMenu is true
  },
  {
    title: "Logo",
    icon: <img className="navIcon" src={greenLogoNoText} alt="logo" />,
  },
  {
    title: "Assessments",
    path: "/assessment",
    icon: <img className="navIcon" src={suitcase} alt="assessments" />,
    canBeLocked: false, // The menu item cannot be locked even if redux state.userData.isLockedFromPrimaryMenu is true
  },

  {
    title: "Data Share Invites",
    path: "/data-share-invites",
    icon: <ShareIcon className="navIcon" />,
    canBeLocked: false, // The menu item cannot be locked even if redux state.userData.isLockedFromPrimaryMenu is true
  },
];

export const MobileSecondaryMenu = [
  {
    title: "Help Center",
    path: "/faq-page",
    icon: <img className="navIcon" src={question} alt="FAQ" />,
  },
  {
    title: "Settings",
    path: "/settings",
    icon: <img className="navIcon" src={cog} alt="settings" />,
  },
];
