import axios from 'axios';
import {ActionTypes} from "../constants/action-types";
import * as Constants from '../../constants'

export function fetchContentfulData() {
    const request = axios.get(`${Constants.CONTENTFUL_API_BASE_URL}/spaces/${Constants.CONTENTFUL_API_SPACE_ID}/entries?access_token=${Constants.CONTENTFUL_API_TOKEN}&include=1`);
    return {
        type: ActionTypes.FETCH_CONTENTFUL_DATA,
        payload: request
    };
}
