import { ActionTypes } from "../constants/action-types";

const INITIAL_STATE = { all: {}, isLockedFromPrimaryMenu: false };

// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SET_USER_DATA:
      // check that data does exist
      if (action && action.payload && action.payload) {
        return { ...state, ...action.payload };
      }
      return state;
    case ActionTypes.SET_USER_ASSESSMENT_REPORTS_AND_DATA:
      if (action && action.payload && action.payload) {
        return {
          ...state,
          all: {
            ...state.all,
            ...action.payload,
          }
        };
      }
      return state;
    default:
      return state;
  }
}
