import { Link } from "react-router-dom";
import {useMemo, useState} from "react";
import { useSelector } from "react-redux";
import { FormControl, TextField } from "@mui/material";
import TalentsApiService from "../../TalentsApiService";
import * as Constants from "./../../constants";
import EmailIcon from "@mui/icons-material/Email";
import {toast, ToastContainer} from "react-toastify";
import Loading from "../../components/reusableElementsComponents/Loading";
import Button from "../../components/reusableElementsComponents/Button";
import { InputAdornment } from "@mui/material";

function RecoverPassword() {
  const apiObject = useMemo(() => {
    return new TalentsApiService();
  }, []);
  const [email, setEmail] = useState("");
  const [disable, setDisable] = useState(true);
  const data = useSelector(
    (state) => state.contentfulData.all["Company recover password page"]
  );

  if (data !== undefined) {
    let content = data.fields;

    const handleSubmit = (e) => {
      e.preventDefault()
      const passwordResetEmailSent = toast.loading(
          "Sending email...",
          {containerId: 'forgot-password-email'}
      );
      if (email) {
        apiObject.ForgotPasswordEmail({ email: email }, (response) =>{
          if (response.status) {
            toast.update(passwordResetEmailSent, {
              render: "Password reset link sent via email",
              type: "success",
              isLoading: false,
              autoClose: '2000',
              closeButton: true,
              hideProgressBar: true
            });
          }
          else{
            if (response.status) {
              toast.update(passwordResetEmailSent, {
                render: "Password reset link sent via email",
                type: "success",
                isLoading: false,
                autoClose: '2000',
                closeButton: true,
                hideProgressBar: true
              });
            }
          }
        })
      }
    };

    const handleBlur = () => {
      let valid = Constants.VALID_EMAIL.test(email);
      if (valid) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    };

    return (
      <div className="auth-page-wrapper">
        <div className="form-wrapper">
          <Link className="back-to-login-link bold reg-as-talent regular" to="/register">
            <p className="m-0 light">
              {'<< '+ content.logBackInButton || "Back to login"}
            </p>
          </Link>
          <br/>
          <ToastContainer containerId="forgot-password-email"/>

          <h3 className="title semi-bold">{content.titleDesktop}</h3>
          <p className="grey s">{content.subTitleDesktop}</p>
          <form className="form" onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <TextField
                  placeholder="Your Account Email Address"
                  id="email"
                  name="email"
                  type="text"
                  autoComplete="on"
                  value={email}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <EmailIcon/>
                        </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            {disable && email.length !== 0 && (
                <div>
                  <p style={{color: "red"}}>Please Enter a Valid E-mail!</p>
                </div>
            )}
            <div className="authButtons">
                <Button
                    btnstyle={"mazhrBtn default-green white"}
                    type="submit"
                    disabled={disable}
                >
                  <p className="m-0 light">
                    {content.recoverPasswordButton || "Send Reset Password Email"}
                  </p>
                </Button>
            </div>
          </form>
        </div>
      </div>
    );
  } else {
    return <Loading/>;
  }
}

export default RecoverPassword;
