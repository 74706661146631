import {useState, useEffect, memo, useMemo} from "react";
import Tables from "@mazhroy/mazhr-data-tables";
import loading from "../../../assets/images/icons/loading.gif";
import TalentsApiService from "../../../TalentsApiService";
import TalentDetails from "../../talentsComponents/TalentDetails";
import { Dialog } from "primereact/dialog";
import * as Constants from "../../../constants";
import { useSelector } from "react-redux";
const TeamStrengths = (props) => {
  const definitions = useSelector(
    (state) =>
      state.contentfulAnalyticsData?.all["teamStrengths" || "team strengths"]
  );
  const scaleDescription = useSelector(
    (state) =>
      state.contentfulAnalyticsData?.all["teamStrengths" || "team strengths"]
        ?.scaleDescription
  );
  const [dataTableList, setDataTableList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [chartDialog, setChartDialog] = useState(false);
  const [showChartBtn, setShowChartBtn] = useState(false);
  const [allCol, setAllCol] = useState([]);
  const [rowData, setRowData] = useState();
  const [userDataDialog, setUserDataDialog] = useState(false);
  const { downloadDataUpdation, columnLabelUpdation } = props;

  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);

  useEffect(() => {
    if (props.tabActiveKey === Constants.TAB_TEAM_STRENGTHS_KEY) {
      getTeamStrength();
      if (columnLabelUpdation) {
        columnLabelUpdation(allCol);
      }
    }
    // eslint-disable-next-line
  }, [props.tabActiveKey, props.selectedTeams]);

  useEffect(() => {
    if (userDataDialog === false) {
      setUserDataDialog(true);
    }
    // eslint-disable-next-line
  }, [rowData]);

  useEffect(() => {
    if (columnLabelUpdation) {
      columnLabelUpdation(allCol);
    }
    // eslint-disable-next-line
  }, [loader]);

  useEffect(() => {
    if (downloadDataUpdation) {
      downloadDataUpdation(dataTableList);
    }

    // eslint-disable-next-line
  }, [dataTableList]);

  const getTeamStrength = () => {
    let hash_list;
    if (props?.selectedTeams?.length > 0) {
      hash_list = props?.selectedTeams?.map((x) => x.hash);
    } else if (props?.allTeams?.length > 0) {
      hash_list = props?.allTeams?.map((x) => x.id);
    } else {
      hash_list = [];
    }
    talentsServiceObj.GetTeamStrengthList(
      {
        company_id: "",
        hash_list: hash_list,
        is_user_pool: props.is_pool_list,
      },
      definitions,
      setDataTableList,
      setAllCol,
      setLoader
    );
  };

  const selectedRow = (data) => {
    if (data) {
      if (downloadDataUpdation) {
        downloadDataUpdation(data);
      }
      setShowChartBtn(true);
    } else {
      setShowChartBtn(false);
      if (downloadDataUpdation) {
        downloadDataUpdation(dataTableList);
      }
    }
  };

  const onChartHide = () => {
    setChartDialog(false);
  };

  const onHide = () => {
    setUserDataDialog(false);
    setRowData();
  };

  return (
    <>
      {loader && !dataTableList ? (
        <div className="page-loading">
          <img src={loading} alt="Loading..." />
        </div>
      ) : (
        <div className="main-div">
          <div className="dropdowns">
            {showChartBtn && (
              <button
                className="mazhrBtn default-green"
                onClick={() => setChartDialog(true)}
              >
                {" "}
                Show Chart
              </button>
            )}
          </div>
          {dataTableList && (
            <>
              {rowData && (
                <Dialog
                  style={{ width: "1000px" }}
                  visible={userDataDialog}
                  onHide={onHide}
                >
                  <TalentDetails
                    candidateId={rowData?.user_id}
                    asEmployee={!props.is_pool_list}
                  />
                </Dialog>
              )}
              <Tables
                tabActiveKey={props.tabActiveKey}
                setRowData={setRowData}
                dataTableList={dataTableList}
                chartDialog={chartDialog}
                selectedRow={selectedRow}
                onChartHide={onChartHide}
                allCol={allCol}
                scaleDescription={scaleDescription}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default memo(TeamStrengths);
