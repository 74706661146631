import * as React from "react";
import arrowRight from "../../../assets/images/icons/arrowRight.svg";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const defaultStepLabels = [
  "Register",
  "Your details",
  "Assessments",
  "Results",
];
const defaultStepLabelsForTeam = ["Register", "Assessments", "Results"];

export default function OnboardingStepper({
  activeStepNumber = 1,
  isTeamMember = false,
  disabled = false,
}) {
  /**
   * We will get the stepLabels from Contentful / hardcoded multi language localization
   */

  const stepLabels = isTeamMember
    ? defaultStepLabelsForTeam
    : defaultStepLabels;

  if (disabled) {
    return null;
  } else {
    return (
      <div className="onboarding-stepper-wrapper">
        {stepLabels.map((stepLabel, index) => {
          const stepNumber = index + 1;
          return (
            <div className="onboarding-stepper-step" key={index}>
              <span
                className={
                  activeStepNumber === stepNumber
                    ? "onboarding-stepper-step-number-active"
                    : "onboarding-stepper-step-number"
                }
              >
                {stepNumber}
              </span>

              <span
                className={
                  activeStepNumber === stepNumber
                    ? "onboarding-stepper-step-label-active"
                    : "onboarding-stepper-step-label"
                }
              >
                {stepLabel}

                {stepNumber === stepLabels.length ? (
                  ""
                ) : (
                  <span className={`onboarding-stepper-step-arrow`}>
                    {ArrowForwardIosIcon ? (
                      <ArrowForwardIosIcon />
                    ) : (
                      <svg src={arrowRight} alt="arrow right" />
                    )}
                  </span>
                )}
              </span>
            </div>
          );
        })}
      </div>
    );
  }
}
