import { ActionTypes } from "../constants/action-types";
import { FAQCATEGORIES } from "../../constants";

const INITIAL_STATE = {
  all: {},
};
// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.FETCH_CONTENTFUL_DATA:
      let data = {};
      let rawFaqs = [];

      //put images in a separate object i.e imagesObj
      let assetsObj = action.payload.data.includes.Asset;

      //loop through items
      // eslint-disable-next-line
      let faqListData = {};
      action.payload.data.items.map((field) => {
        let fieldData = {
          fields: field.fields,
          images: {},
        };

        if (
          !fieldData?.fields?.pageTitleDoNotEdituse &&
          fieldData?.fields?.question
        ) {
          faqListData = {
            question: field.fields.question,
            answer: field.fields.answer,
            id: field?.sys?.id,
          };
          rawFaqs.push(faqListData);
        }
        //check if items have images
        if (field.fields.images !== undefined) {
          //loop through images
          // eslint-disable-next-line
          field.fields.images.map((image) => {
            //get image id.
            const imgId = image.sys.id;

            //search in imagesObj
            // eslint-disable-next-line
            assetsObj.map((asset) => {
              if (asset.sys && asset.sys.id && imgId === asset.sys.id) {
                let imgTitle = String(asset.fields.title).trim();
                fieldData.images[imgTitle] = asset.fields.file.url;
              }
            });
          });
        }

        let formatedTitle = String(field.fields.pageTitleDoNotEdituse).trim();
        data[formatedTitle] = fieldData;
        return true;
      });
      restructureFaqs(rawFaqs, data);
      return { ...state, all: data };

    case ActionTypes.FETCH_CONTENTFUL_TALENT_APP_DATA:
      let talentAppData = {};
      let assessments = {};

      //put images in a separate object i.e imagesObj
      let talentAppAssetsObj = action.payload.data.includes.Asset;

      //loop through items
      // eslint-disable-next-line
      action.payload.data?.items?.map((item) => {
        const contentType = item.sys?.contentType?.sys?.id || null;
        const { content, ...rest } = item.fields;
        let fieldData = {
          fields: { ...content, ...rest },
          images: {},
        };
        //check if items have images
        if (item.fields?.images !== undefined) {
          //loop through images
          // eslint-disable-next-line
          item.fields?.images?.map((image) => {
            //get image id.
            const imgId = image.sys?.id;

            //search in imagesObj
            // eslint-disable-next-line
            talentAppAssetsObj.map((asset) => {
              if (asset.sys && asset.sys.id && imgId === asset.sys.id) {
                let imgTitle = String(asset.fields.title).trim();
                fieldData.images[imgTitle] = asset.fields.file.url;
              }
            });
          });
        }

        //Check if items have reference content
        if (
          item.fields?.referenceContent !== undefined &&
          Array.isArray(item.fields?.referenceContent)
        ) {
          //loop through reference content
          fieldData.fields.referenceContent =
            item.fields?.referenceContent?.map((reference) => {
              //get reference id.
              const referenceId = reference.sys?.id;

              //search in referenceObj
              // eslint-disable-next-line
              const match = action.payload.data?.includes?.Entry?.find(
                (includesEntry) => referenceId === includesEntry.sys?.id
              );
              if (match) {
                return {
                  id: referenceId,
                  ...match.fields,
                };
              } else {
                return {
                  id: referenceId,
                };
              }
            });
        }

        let formatedTitle = String(item.fields.pageTitleDoNotEditUse).trim();
        if (contentType === "assessment") {
          assessments[formatedTitle] = fieldData;
          return true;
        }

        talentAppData[formatedTitle] = fieldData;
        return true;
      });
      return {
        ...state,
        talentAppData: talentAppData,
        assessments: assessments,
      };

    default:
      return state;
  }
  function restructureFaqs(rawFaqs, data) {
    FAQCATEGORIES.forEach((faqCategory) => {
      let structuredFaqs = [];
      data[faqCategory]?.fields?.faqList.map((faqObj) => {
        rawFaqs.map((rawFaqObj) => {
          if (rawFaqObj.id === faqObj.sys.id) {
            structuredFaqs.push(rawFaqObj);
          }
          return true;
        });
        return true;
      });
      data[faqCategory] = structuredFaqs;
      return true;
    });
  }
}
