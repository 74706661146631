import { ActionTypes } from "../constants/action-types";

const INITIAL_STATE = { all: [], requiredAssessments: [] };

// eslint-disable-next-line
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ActionTypes.SET_TEAM_DATA:
      // check that data does exist
      if (action && action.payload) {
        return { ...state, all: [...state.all, action.payload] };
      }
      return state;
    case ActionTypes.SET_REQUIRED_ASSESSMENTS:
      // check that data does exist
      if (action && action.payload) {
        return {
          ...state,
          requiredAssessments: [
            ...new Set([...state.requiredAssessments, ...action.payload]),
          ],
        };
      }
      return state;
    default:
      return state;
  }
}
