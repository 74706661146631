import loading from "../../assets/images/icons/loading.gif";

function Loading({ sectionLoading, containerHeight }) {
  return sectionLoading ? (
    <div
      className="section-loading"
      style={{ height: containerHeight || "100%" }}
    >
      <img className="gif" src={loading} alt="Loading..." />
    </div>
  ) : (
    <div className="loading">
      <img className="gif" src={loading} alt="Loading..." />
    </div>
  );
}

export default Loading;
