import React from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/reusableElementsComponents/Loading";
import PageLayout from "../../components/layoutComponents/PageLayout";
import * as Utilities from "../../Utilities";

function Faq() {
  const pageData = useSelector((state) =>
    state.contentfulData?.talentAppData
      ? state.contentfulData?.talentAppData["faq"]
      : null
  );

  if (pageData && !Utilities.isEmptyObject(pageData?.fields)) {
    const { titleDesktop, referenceContent } = pageData?.fields;

    return (
      <div className="bg-cover">
        <PageLayout>
          <div className="section-wrapper">
            <h1>{titleDesktop || "Frequently Asked Questions"}</h1>
          </div>
        </PageLayout>
        <PageLayout>
          <div className="section-wrapper">
            {referenceContent.map((item, index) => {
              return (
                <div key={index} className="mar-25 bottom">
                  <p className="bold m-10 bottom">{item.title}</p>
                  <p>{item.description}</p>
                </div>
              );
            })}
          </div>
        </PageLayout>
      </div>
    );
  } else {
    return <Loading />;
  }
}

export default Faq;
