import React, {useEffect, useMemo, useState} from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as Constants from "./../../constants";
import Button from "../../components/reusableElementsComponents/Button";
import { InputAdornment } from "@mui/material";
import {toast, ToastContainer} from "react-toastify";
import LockIcon from "@mui/icons-material/Lock";
import { FormControl, TextField } from "@mui/material";
import TalentsApiService from "../../TalentsApiService";
// import HubspotForm from "../../components/reusableElementsComponents/HubspotForm";
// import * as Constants from '../../constants'

function SetPassword() {
  const pageData = useSelector((state) =>
    state.contentfulData.talentAppData
      ? state.contentfulData.talentAppData["set-password"]
      : null
  );
  const api = useMemo(() => {
    return new TalentsApiService();
  }, []);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(false);
  const [token, setToken] = useState("");
  const [submitting, setSubmitting] = useState(false); // State to track form submission
  const [isKeyValid, setIsKeyValid] = useState(false); // State to track form submission

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const accessToken = query.get("key");
    api.ValidateResetPasswordKey({ key: accessToken}).then((response) => {
      if (response.httpStatus === 200) {
        if(response.json?.status){
          setToken(accessToken);
          setIsKeyValid(true)
        }
      }
    });
  }, [api]);

  useEffect(() => {
    if (password.length !== 0) {
      if (password === confirmPassword) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [password, confirmPassword]);

  const handleBlur = () => {
    let valid = Constants.VALID_PASSWORD.test(password);
    // eslint-disable-next-line
    let validReconfirm = Constants.VALID_PASSWORD.test(confirmPassword);
    if (valid) {
      setError(false);
    } else {
      setError(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const passwordResetEmailSent = toast.loading(
        "Resetting password...",
        {containerId: 'reset-password'}
    );
    api.SetNewPassword({ key: token, password: password }).then((response) => {
      if (response.httpStatus === 200) {
        setSubmitting(true);
        toast.update(passwordResetEmailSent, {
          render: "Password updated successfully.You can login using new password",
          type: "success",
          isLoading: false,
          autoClose: '2000',
          closeButton: true,
          hideProgressBar: true
        });
      }
      else{
        toast.update(passwordResetEmailSent, {
          render: "Unable to reset password",
          type: "error",
          isLoading: false,
          autoClose: '2000',
          closeButton: true,
          hideProgressBar: true
        });
      }
    });
  };

  return (
    <div className="auth-page-wrapper">
      <div className="form-wrapper">
        {!isKeyValid && (
            <>
              <h3 className="title semi-bold">Invalid Reset Password Link</h3>
              <Link className="back-to-login-link bold reg-as-talent regular" to="/recover-password">
                <p className={`m-0 light ${submitting ? "bold text-center" : ""}`}>
                  {'<< Request New Link'}
                </p>
              </Link>
            </>
        )}
        {isKeyValid && (<Link className="back-to-login-link reg-as-talent regular" to="/login">
          <p className={`m-0 light ${submitting ? "bold text-center" : ""}`}>
            {'<< ' + (pageData?.fields?.logBackInButton || "Back to login")}
          </p>
        </Link>)}
        <br/>
        <ToastContainer containerId="reset-password"/>
        {isKeyValid && (<div className="section" style={{"display": `${submitting ? "none" : ""}`}}>
          <h3 className="title semi-bold">
            {pageData?.fields?.titleDesktop || "Set Your New Passwords"}
          </h3>
          <form className="form" onSubmit={handleSubmit}>
            <FormControl fullWidth>
              <TextField
                  placeholder="Enter Password..."
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="on"
                  value={password}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon/>
                        </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            {error && (
                <div>
                  <p style={{color: "red"}}>
                    Password must contain at least 8 characters, a capital letter,
                    a number and a special symbol
                  </p>
                </div>
            )}
            <FormControl fullWidth>
              <br/>
              <TextField
                  placeholder="Confirm Password..."
                  id="passwordconfirmation"
                  name="password"
                  type="password"
                  autoComplete="on"
                  value={confirmPassword}
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <LockIcon/>
                        </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormControl>
            {/* {error &&
                    <div>
                        <p style={{ color: "red" }}>
                            Password must contain at least 8 characters, a capital letter, a number and a special symbol
                        </p>
                    </div>
                } */}
            {disabled && confirmPassword.length !== 0 && (
                <div>
                  <p style={{color: "red"}}>Both Passwords Must Match</p>
                </div>
            )}

            <div className="authButtons">
              <Button
                  type="submit"
                  btnstyle={
                    disabled ? "mazhrBtn default" : "mazhrBtn default-green"
                  }
                  disabled={disabled}
              >
                {pageData?.fields?.saveChanges || "Reset Password"}
              </Button>
            </div>
          </form>
        </div>)}
      </div>
    </div>
  );
}

export default SetPassword;
