import {useState, useEffect, useMemo} from "react";
import PageLayout from "../../components/layoutComponents/PageLayout";
import TabsMenu from "../../components/reusableElementsComponents/TabsMenu";
import CompetencePoints from "../../components/Analytics/competencePoints/competencePoints";
//import AdeptBehaviours from "../../components/Analytics/adeptBehaviours/adeptBehaviours";
import AdeptAspects from "../../components/Analytics/adeptBehaviours/adeptAspects";
import FileDownload from "./FileDownload";
import MotivationStrengths from "../../components/Analytics/motivationStrengths/motivationStrengths";
import SalesReport from "../../components/Analytics/salesReportComponent/salesReport";
import TeamStrengths from "../../components/Analytics/teamStrengths/teamStrengths";
import NoDataHome from '../../components/homeComponents/NoDataHome';
import TalentsApiService from "../../TalentsApiService";
import { fetchContentfulAnalyticsData } from "../../store/actions/contentfulAnalyticsDataActions";
import {useDispatch, useSelector} from "react-redux";

function TeamAnalytics() {
  const sharedDataTeamHash = useSelector(
      (state) => state.userData?.all?.shared_data_team_hash
  );
  const [selectedTeams, setSelectedTeams] = useState([sharedDataTeamHash]);
  const [downloadData, setDownloadData] = useState();
  const [columnLabels, setColumnLables] = useState();
  const [tabActiveKey, setTabActiveKey] = useState("1");

  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    talentsServiceObj.LoadTeamByHash(sharedDataTeamHash, setSelectedTeams)
    dispatch(fetchContentfulAnalyticsData());
  }, [dispatch, sharedDataTeamHash, talentsServiceObj]);

  const activeKey = (key) => {
    key = (+key + 1).toString();
    setTabActiveKey(key);
  };

  const columnLabelUpdation = (data) => {
    if (data) {
      setColumnLables(data);
    }
  };

  const downloadDataUpdation = (data) => {
    if (data) {
      setDownloadData(data);
    }
  };

  const template = {
    tabs: [
      {
        component: (
          <CompetencePoints
            columnLabelUpdation={columnLabelUpdation}
            downloadDataUpdation={downloadDataUpdation}
            tabActiveKey={tabActiveKey}
          />
        ),
        sectionTitle: "COMPETENCIES",
      },
      {
        component: (
          <MotivationStrengths
            columnLabelUpdation={columnLabelUpdation}
            downloadDataUpdation={downloadDataUpdation}
            tabActiveKey={tabActiveKey}
          />
        ),
        sectionTitle: "MOTIVATIONS",
      },
      {
        component: (
          <SalesReport
            downloadDataUpdation={downloadDataUpdation}
            columnLabelUpdation={columnLabelUpdation}
            tabActiveKey={tabActiveKey}
          />
        ),
        sectionTitle: "SALES STRENGTHS",
      },
      {
        component: (
          <TeamStrengths
            columnLabelUpdation={columnLabelUpdation}
            downloadDataUpdation={downloadDataUpdation}
            tabActiveKey={tabActiveKey}
          />
        ),
        sectionTitle: "TEAM STRENGTHS",
      },
      {
        component: (
            <AdeptAspects
                columnLabelUpdation={columnLabelUpdation}
                downloadDataUpdation={downloadDataUpdation}
                tabActiveKey={tabActiveKey}
            />
        ),
        sectionTitle: "Adept",
      },
      /*{
        component: <BehaviourStrengths selectedTeams={selectedTeams} tabActiveKey={tabActiveKey} />,
        sectionTitle: 'BEHAVIOURS'
      },*/
    ],
  };


  return (
    <PageLayout>
      {!sharedDataTeamHash &&
          <div className="dashboard">
            <div className="no-data-desktop">
              <NoDataHome/>
            </div>
          </div>}
      {sharedDataTeamHash && <>
        <div
            className="full-width p-20 desktop"
            style={{display: "flex", justifyContent: "space-between"}}
        >
          <div className="title">
            <h4 className="name m-0">
              {selectedTeams.team?.title} - {selectedTeams.company?.name}
            </h4>
        </div>
        <FileDownload
            tabActiveKey={tabActiveKey}
            columnLabels={columnLabels}
            downloadData={downloadData}
            selectedTeams={selectedTeams}
        ></FileDownload>
      </div>

      <div className="tabs-wrapper background-green tabs-styling">
        <TabsMenu
          template={template}
          activeKey={activeKey}
        />
      </div>
      </>}
    </PageLayout>
  );
}

export default TeamAnalytics;
