import { ActionTypes } from "../constants/action-types";

export function setUserData(data) {
  return {
    type: ActionTypes.SET_USER_DATA,
    payload: data,
  };
}

export function updateUserAssessmentReportsAndData(data) {
  return {
    type: ActionTypes.SET_USER_ASSESSMENT_REPORTS_AND_DATA,
    payload: data
  };
}
