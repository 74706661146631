import { combineReducers } from "redux";

import contentfulDataReducer from "./contentfulDataReducer";
import contentfulAnalyticsDataReducer from './contentfulAnalyticsDataReducer';
import poolDataReducer from "./poolDataReducer";
import companyReducer from "./companyReducer";
import teamDataReducer from "./teamDataReducer";
import userDataReducer from "./userDataReducer";

const reducers = combineReducers({
  contentfulData: contentfulDataReducer,
  contentfulAnalyticsData: contentfulAnalyticsDataReducer,
  poolData: poolDataReducer,
  teamData: teamDataReducer,
  company: companyReducer,
  userData: userDataReducer,
});

export default reducers;
