import React, { useMemo, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as Constants from "../../constants";

import PageLayout from "../../components/layoutComponents/PageLayout";
import Loading from "../../components/reusableElementsComponents/Loading";
import { SalesSelect } from "../../components/formComponents/Inputs";
import { FormControlLabel, Checkbox } from "@mui/material";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import DownloadIcon from '@mui/icons-material/Download';

import TalentsApiService from "../../TalentsApiService";

import { fetchContentfulTalentAppData } from "../../store/actions/contentfulTalentAppDataActions";

import MazhrModal from "../../components/reusableElementsComponents/MazhrModal";
import AdeptAspectsSection from "../../components/TalentMatchScoreComponents/AdeptAspectsSection";
import SalesStrengthsSection from "../../components/TalentMatchScoreComponents/SalesStrengthsSection";
import TeamStrengthsSection from "../../components/TalentMatchScoreComponents/TeamStrengthsSection";
import CandidateMotivationFactors from "../../components/talentsComponents/CandidateMotivationFactors";
import CandidateCompetences from "../../components/talentsComponents/CandidateCompetences";

const assessmentCodeConvert = {
  "work-personality-and-behaviour": 102,
  "values-and-motivation": 201,
  "verbal-reasoning": 302,
  "numerical-reasoning": 301,
  "deductive-reasoning": 344,
  "english": 370,
  "adept-15-personality-assessment": 390,
};


function Assessment() {
  // Extract path params from URL and get assessment name space
  let { assessmentPathName } = useParams();

  /**
   * STATE VARIABLES AND OBJECTS DECLARATION
   */
  const pageData = useSelector(
    (state) => state.contentfulData?.assessments?.[assessmentPathName]
  );
  const userCompletedAssessments =
    useSelector((state) => state.userData?.all?.assessment_report_list) || null;
  const [loader, setLoader] = useState(false);
  // const [pageData, setPageData] = useState(null);
  const [languageChoice, setLanguageChoice] = useState(null);
  const [rememberChoice, setRememberChoice] = useState(false);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);
  const [assessment, setAssessment] = useState(null); // Example: { "score_url": "https://ws01.maptq.com/..", "assessment_name": "personality-and-behaviour", "instrument_id": 102}
  const [openRetakeDialog, setOpenRetakeDialog] = useState(false);

  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);
  const dispatch = useDispatch();

  /**
   * ALL USE EFFECTS
   */
  useEffect(() => {
    // Fetch contentful data if not available
    if (!pageData) {
      setLoader(true);
      dispatch(fetchContentfulTalentAppData());
    } else if (pageData) {
      setLoader(false);
    }
  }, [pageData, dispatch]);

  useEffect(() => {
    // Set default language choice
    setLanguageChoice(Constants.languageSelectOptions[1]);
  }, []);

  useEffect(() => {
    if (assessmentPathName && userCompletedAssessments?.length > 0) {
      let match = userCompletedAssessments?.find(
        (x) => x.instrument_id === assessmentCodeConvert[assessmentPathName]
      );
      if (match) {
        setAssessmentCompleted(true);
        setAssessment(match);
      }
    }
  }, [assessmentPathName, userCompletedAssessments]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const statusParam = urlSearchParams.get("status");
    const validParam = urlSearchParams.get("valid");
    const instrumentIdParam = urlSearchParams.get("instr");

    // Check if the hash parameter exists and do something
    if (statusParam === "4" && validParam === "1") {
      talentsServiceObj.FetchAssessmentsResults("AON", instrumentIdParam);
    }
  }, [talentsServiceObj]);

  /**
   * ALL ACTION HANDLERS
   */
  const handleSelectChange = (e) => {
    const { value } = e.target;
    setLanguageChoice(Constants.languageSelectOptions.find((x) => x.title === value));
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;
    setRememberChoice(checked);
  };

  // Handle "Start / or Retake test" button click
  const handleStartTest = (providerName, instrumentId, languageId) => {
    talentsServiceObj
      .GetAssessmentUrl(
        providerName || "aon",
        instrumentId || 102,
        languageId || 2
      )
      .then((res) => {
        if (res.httpStatus === 200 && res.json && res.json.data) {
          const url = res.json.data?.url;
          if (url && url.includes("https://", 0)) {
            window.location.assign(url);
          } else {
            console.log("GetAssessmentUrl url is not valid  >> ", url);
          }
        } else {
          console.log("GetAssessmentUrl error  >> ", res);
        }
      });
  };

  const userData = useSelector((state) => state.userData?.all);
  const copy = useSelector(
      (state) => state.contentfulData.all["talent-data"]?.fields
  );

  /**
   * ALL RENDER FUNCTIONS
   */
  const renderTestInstruction = (
    assessmentCompleted,
    instruction,
    duration,
    selectTestLanguage,
    startTestButtonText,
    rememberChoiceText,
    instrumentId
  ) => {
    return (
      <>
        <div className="test-instruction">
          <div className="test-instruction-column test-instruction-column-left">
            <p className="test-instruction-heading">
              {assessmentCompleted ? (
                <h3>Retake the test</h3>
              ) : (
                <h3>Instructions</h3>
              )}{" "}
              {duration && (
                <span className="bold">
                  <AccessAlarmIcon /> {duration}
                </span>
              )}
            </p>
            <ul>
              {instruction &&
                instruction.split("-").map((item, index) => (
                  <li key={index} className="test-instruction-list-item">
                    {item}
                  </li>
                ))}
            </ul>
          </div>

          <div className="test-instruction-column">
            <p className="bold">
              <h3>{selectTestLanguage || "Select test language"}</h3>
            </p>
            <SalesSelect
              label=""
              options={Constants.languageSelectOptions}
              handleChange={handleSelectChange}
              errorContent={"Please select group"}
              option={languageChoice?.title}
              inputProps={{ style: {} }}
            />
            <FormControlLabel
              className="test-instruction-select-language"
              control={
                <Checkbox
                  checked={rememberChoice}
                  name="remeber_language_choice"
                  onChange={handleCheckboxChange}
                />
              }
              label={
                <span>
                  {rememberChoiceText || "Remember my choice for later tests"}
                </span>
              }
              sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
            />

            {assessmentCompleted ? (
              <button
                className="mazhrBtn default-green white"
                onClick={() => setOpenRetakeDialog(true)}
              >
                Retake the test
              </button>
            ) : (
              <button
                className="mazhrBtn default-green white"
                onClick={() =>
                  handleStartTest(
                    "aon",
                    instrumentId || assessmentCodeConvert[assessmentPathName],
                    languageChoice.code
                  )
                }
              >
                {startTestButtonText || "Start test"}
              </button>
            )}
          </div>
        </div>
      </>
    );
  };

  /**
   * MAIN COMPONENT RENDER
   */
  if (!loader && pageData?.fields) {
    let {
      instrumentId,
      titleDesktop,
      whatItMeasures,
      recommendedFor,
      recommendedFields,
      instruction,
      duration,
      importance,
      selectTestLanguage,
      rememberChoice,
      startTestButtonText,
    } = pageData?.fields;

    return (
      <>
        <div className="bg-cover">
          <PageLayout>
            <div className="assessment-page-wrapper">
              <h1>{titleDesktop || "Assessment"}</h1>
              {recommendedFor && <p>{recommendedFor}</p>}
              {recommendedFields && (
                <div className="assessment-page-tag-wrapper">
                  {recommendedFields.map((field, index) => (
                    <span className="assessment-page-tag" key={index}>
                      {field}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </PageLayout>
          <PageLayout>
            {/* Wait for the userCompletedAssessments to be fetched before render the page body, in order to avoid mismatch data display */}
            {userCompletedAssessments ? (
                <div className="assessment-page-wrapper">
                  {assessmentCompleted && (
                      <>
                        <h3>You have already completed this test
                          <Link
                              to={assessment?.score_url || "#"}
                          >
                            &nbsp;&nbsp;     ( <DownloadIcon/> Report )
                          </Link>
                        </h3>

                        <hr/>
                      </>
                  )}
                  <div>
                    <div className='test-measure-importance-column'>
                      {importance && (
                          <>
                            <h3>Importance</h3>
                            <p>{importance}</p>
                          </>
                      )}
                    </div>
                  </div>

                  <hr/>

                  <div>
                    {/*ADEPT ASPECTS SECTION*/}
                    <div className='test-measure-importance-column'>
                      {whatItMeasures && (
                          <>
                            <h3>What it measures</h3>
                            <p>{whatItMeasures}</p>
                          </>
                      )}
                    </div>
                    <div>
                      {/*SALES STRENGTH SECTION*/}
                      {assessment?.instrument_id === 390 && userData?.adept_aspects_points?.length === 15 &&
                          <AdeptAspectsSection data={userData}/>}

                      {/*TEAM STRENGTH SECTION*/}
                      {assessment?.instrument_id === 102 && userData?.team_strengths?.length === 9 &&
                          <TeamStrengthsSection data={userData} copy={copy}/>
                      }

                      {/*SALES STRENGTH SECTION*/}
                      {assessment?.instrument_id === 102 && userData?.sales_strengths?.competence?.length === 8 &&
                          <SalesStrengthsSection data={userData} copy={copy}/>
                      }

                      {assessment?.instrument_id === 102 &&
                          <CandidateCompetences data={userData} />
                      }

                      {assessment?.instrument_id === 201 &&
                        <CandidateMotivationFactors data={userData} />
                      }
                    </div>
                  </div>

                  <hr/>

                  {renderTestInstruction(
                      assessmentCompleted,
                      instruction,
                      duration,
                      selectTestLanguage,
                      startTestButtonText,
                      rememberChoice,
                      instrumentId || assessment.instrument_id
                  )}
                </div>
            ) : (
                <Loading sectionLoading containerHeight="50vh"/>
            )}
          </PageLayout>
          <MazhrModal
              show={openRetakeDialog}
              onHide={() => setOpenRetakeDialog(false)}
              header={""}
          >
            <h3 className="center-content">
              Retaking this test will reset your current result
            </h3>

            <div className="alert">
              <button
                className="mazhrBtn default-green-border"
                onClick={() => setOpenRetakeDialog(false)}
              >
                Cancel
              </button>
              <button
                className="mazhrBtn default-green mar-20 left"
                onClick={() =>
                  handleStartTest(
                    "aon",
                    instrumentId || assessmentCodeConvert[assessmentPathName],
                    languageChoice.code
                  )
                }
              >
                Retake
              </button>
            </div>
          </MazhrModal>
        </div>
      </>
    );
  } else {
    return <Loading />;
  }
}

export default Assessment;
