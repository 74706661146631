import React, {useState, useEffect, useCallback} from 'react';
import StrengthsRadarChart from '../reusableElementsComponents/StrengthsRadarChart';
import RadarChartInfo from '../talentsComponents/RadarChartInfo';
import * as Utilities from '../../Utilities';

const AdeptAspectsSection = ({ data }) => {
    const calculateAdeptStyles = useCallback(() => {
        if(data?.adept_aspects_points?.length === 15) {

            const candidate = {
                Drive: data?.adept_aspects_points[0],
                Structure: data?.adept_aspects_points[1],
                Conceptual: data?.adept_aspects_points[2],
                Flexibility: data?.adept_aspects_points[3],
                Mastery: data?.adept_aspects_points[4],
                Ambition: data?.adept_aspects_points[5],
                Power: data?.adept_aspects_points[6],
                Assertiveness: data?.adept_aspects_points[7],
                Liveliness: data?.adept_aspects_points[8],
                Composure: data?.adept_aspects_points[9],
                Positivity: data?.adept_aspects_points[10],
                Awareness: data?.adept_aspects_points[11],
                Cooperativeness: data?.adept_aspects_points[12],
                Sensitivity: data?.adept_aspects_points[13],
                Humility: data?.adept_aspects_points[14]
            };

            const Task = Utilities.calculateAverageCeiling([candidate.Drive, candidate.Structure]);
            const Adaptation = Utilities.calculateAverageCeiling([candidate.Conceptual, candidate.Flexibility, candidate.Mastery]);
            const Achievement = Utilities.calculateAverageCeiling([candidate.Ambition, candidate.Power]);
            const Teamwork = Utilities.calculateAverageCeiling([candidate.Cooperativeness, candidate.Sensitivity, candidate.Humility]);
            const Emotional = Utilities.calculateAverageCeiling([candidate.Composure, candidate.Positivity, candidate.Awareness]);
            const Interaction = Utilities.calculateAverageCeiling([candidate.Assertiveness, candidate.Liveliness]);

            return [
                Task,
                Adaptation,
                Achievement,
                Teamwork,
                Emotional,
                Interaction
            ];
        }else{
            return []
        }
    },[data?.adept_aspects_points])

    const [AdeptAspectsData, setAdeptAspectsData] = useState({
        labels: ["Task", "Adaptation", "Achievement", "Teamwork", "Emotional", "Interaction"],
        datasets: [
            {
                label: "Adept Work Styles",
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: "rgba(255,99,132,1)",
                pointBackgroundColor: "rgba(255,99,132,1)",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "rgba(255,99,132,1)",
                data: calculateAdeptStyles(),
            },
        ],
    });

    useEffect(() => {
        setAdeptAspectsData({
            labels: ["Task", "Adaptation", "Achievement", "Teamwork", "Emotional", "Interaction"],
            datasets: [
                {
                    label: "Adept Work Styles",
                    backgroundColor: "rgba(255,99,132,0.2)",
                    borderColor: "rgba(255,99,132,1)",
                    pointBackgroundColor: "rgba(255,99,132,1)",
                    pointBorderColor: "#fff",
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: "rgba(255,99,132,1)",
                    data: calculateAdeptStyles(),
                },
            ],
        });
    }, [data?.adept_aspects_points,calculateAdeptStyles]);

    const lightOptionsTeams = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
                position: "bottom",
            },
        },
        scales: {
            r: {
                min: 0,
                max: 9,
                ticks: {
                    stepSize: 1,
                },
                pointLabels: {
                    fontSize: 14,
                    color: "#495057",
                    padding: 0,
                    margin: 0,
                },
                grid: {
                    color: "#ebedef",
                },
                angleLines: {
                    color: "#ebedef",
                },
            },
        },
    };

    return (
        <div className="row chart-with-info-section">
            <div className="col-6 vertical-divider">
                <div className="talent-details-strengths-charts">
                    <StrengthsRadarChart
                        title="Adept Work Styles"
                        data={AdeptAspectsData}
                        options={lightOptionsTeams}
                    />
                </div>
            </div>

            <div className="col-6">
                <div className="motivation" id="boxStyleMot">
                    <RadarChartInfo
                        title="Adept Work Styles Info"
                        tabActiveKey={"5"} // Make sure to pass the correct active tab key
                        radarLabel={"Task"} // Make sure to pass the correct radar label
                    />
                </div>
            </div>
        </div>
    );
};

export default AdeptAspectsSection;
