import { useEffect, useMemo, useRef, useState } from "react";
import TalentMatchScore from "./TalentMatchScore";
import TalentsApiService from "../../TalentsApiService";
import { PDFExport } from "@progress/kendo-react-pdf";

function TalentDetails({
  matchPercentage = undefined,
}) {
  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);

  const talentDetailsRef = useRef(null);

  let [candidateData, setCandidateData] = useState(null);

  //Variables for invite anonymous to pool
  const [showMore, setShowMore] = useState(false);
  const [wordCount, setWordCount] = useState(25);

  const text = candidateData?.summary;
  const words = text?.split(" ").slice(0, wordCount);

  const handleShowMore = () => {
    setShowMore(!showMore);
    if (!showMore) {
      setWordCount(text.split(" ").length);
    } else {
      setWordCount(25);
    }
  };

  useEffect(() => {
    talentsServiceObj.GetCurrentUserDetails(setCandidateData);
  }, [talentsServiceObj]);

  useEffect(() => {
    // Function to recalculate and update the height of the target div
    const updateViewportDivHeight = () => {
      if (candidateData) {
        const viewportDiv = document.getElementById("viewportDiv");

        // Calculate the desired height
        const viewportHeight = window.innerHeight;
        const fixedValue = 220;
        const desiredHeight = viewportHeight - fixedValue;

        // Set the height of the target div
        viewportDiv.style.height = desiredHeight + "px";
      }
    };

    // Initial height calculation
    updateViewportDivHeight();

    // Event listener for viewport height changes
    window.addEventListener("resize", updateViewportDivHeight);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateViewportDivHeight);
    };
  }, [candidateData]);

  return (
    <>
      {candidateData ? (
        <PDFExport
          ref={talentDetailsRef}
          margin={0}
          imageType="jpeg"
          imageQuality={0.98}
          scale={2}
          paperSize="A4"
        >
          <div className="talent-details" id="viewportDiv">
            <div className="borderedBox">
              <div className="basic-info">
                <div className="description d-flex flex-column">
                  <div className="match-heading">
                    <div className="title">
                      <h4 className="name m-0">
                        {candidateData.info.first} {candidateData.info.last}
                      </h4>
                      {matchPercentage !== undefined && matchPercentage !== 0 && (
                        <p
                          className={
                            "match l bold m-10 left " +
                            (matchPercentage >= 70
                              ? "green"
                              : matchPercentage >= 40
                              ? "yellow"
                              : "purple")
                          }
                        >
                          {matchPercentage + "% match"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    {candidateData.headline && (
                      <h6 className="short-description m">
                        {candidateData.headline}
                      </h6>
                    )}
                    {candidateData.summary && (
                      <p className="long-description s">
                        {words.join(" ")}{" "}
                        {text.split(" ").length > 25 && (
                          <button
                            onClick={handleShowMore}
                            className="show-more-link link"
                          >
                            {showMore ? "Show Less" : "Show More"}
                          </button>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <TalentMatchScore
              candidateData={candidateData}
              matchPercentage={matchPercentage}
            />
          </div>
        </PDFExport>
      ) : null}
    </>
  );
}

export default TalentDetails;
