import React, { useState, useEffect } from 'react';
import StrengthsRadarChart from '../reusableElementsComponents/StrengthsRadarChart';
import RadarChartInfo from '../talentsComponents/RadarChartInfo';

const SalesStrengthsSection = ({ data, copy }) => {
    const [salesChartData, setSalesChartData] = useState({
        labels: copy?.salesStrengthsLabels,
        datasets: [
            {
                label: "Competence",
                backgroundColor: "rgba(179,181,198,0.2)",
                borderColor: "rgba(179,181,198,1)",
                pointBackgroundColor: "rgba(179,181,198,1)",
                pointHoverBorderColor: "rgba(179,181,198,1)",
                data: data.sales_strengths.competence,
            },
            {
                label: "Attitude",
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: "rgba(255,99,132,1)",
                pointBackgroundColor: "rgba(255,99,132,1)",
                pointHoverBorderColor: "rgba(255,99,132,1)",
                data: data.sales_strengths.attitude,
            },
        ],
    });

    useEffect(() => {
        setSalesChartData({
            labels: copy?.salesStrengthsLabels,
            datasets: [
                {
                    label: "Competence",
                    backgroundColor: "rgba(179,181,198,0.2)",
                    borderColor: "rgba(179,181,198,1)",
                    pointBackgroundColor: "rgba(179,181,198,1)",
                    pointHoverBorderColor: "rgba(179,181,198,1)",
                    data: data.sales_strengths.competence,
                },
                {
                    label: "Attitude",
                    backgroundColor: "rgba(255,99,132,0.2)",
                    borderColor: "rgba(255,99,132,1)",
                    pointBackgroundColor: "rgba(255,99,132,1)",
                    pointHoverBorderColor: "rgba(255,99,132,1)",
                    data: data.sales_strengths.attitude,
                },
            ],
        });
    }, [data.sales_strengths, copy?.salesStrengthsLabels]);

    const lightOptionsSales = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
                position: "bottom",
            },
        },
        scales: {
            r: {
                max: 5,
                min: 0,
                stepSize: 1,
                ticks: {
                    stepSize: 1,
                },
                pointLabels: {
                    fontSize: 14,
                    color: "#495057",
                    padding: 0,
                    margin: 0,
                },
                grid: {
                    color: "#ebedef",
                },
                angleLines: {
                    color: "#ebedef",
                },
            },
        },
    };

    return (
        <div className="row chart-with-info-section">
            <div className="col-6 vertical-divider">
                <div className="talent-details-strengths-charts">
                    <StrengthsRadarChart
                        title="Sales Strengths"
                        data={salesChartData}
                        options={lightOptionsSales}
                    />
                </div>
            </div>

            <div className="col-6">
                <div className="motivation" id="boxStyleMot">
                    <RadarChartInfo
                        title="Sales Strengths Info"
                        tabActiveKey={"3"} // Make sure to pass the correct active tab key
                        radarLabel={"Preparing The Ground"} // Make sure to pass the correct radar label
                    />
                </div>
            </div>
        </div>
    );
};

export default SalesStrengthsSection;
