import { ActionTypes } from "../constants/action-types";

export function setTeamData(data) {
  return {
    type: ActionTypes.SET_TEAM_DATA,
    payload: data,
  };
}

export function setRequiredAssessments(data) {
  return {
    type: ActionTypes.SET_REQUIRED_ASSESSMENTS,
    payload: data,
  };
}
