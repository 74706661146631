import { useLocation } from 'react-router-dom';

import TalentDetails from "../../components/talentsComponents/TalentDetails";
import React from "react";
import PageLayout from "../../components/layoutComponents/PageLayout";
function Dashboard() {
    const location = useLocation();
    const currentURL = location.pathname;

    return (
        <PageLayout>
            {currentURL !== "/faq-page" ?
                (
                            <div style={{overflow:'auto', height:'100vh', backgroundColor: "#edf1f0"}}>
                                <TalentDetails/>
                            </div>

                    ): (
                <div className='onboarding-page-help-center'>

                </div>

            )}
        </PageLayout>
    )
}

export default Dashboard;
