export const ActionTypes = {
  SET_COMPANY_STATUS: "SET_COMPANY_STATUS",
  SET_COMPANY_INFO: "SET_COMPANY_INFO",
  FETCH_CONTENTFUL_DATA: "FETCH_CONTENTFUL_DATA",
  FETCH_CONTENTFUL_TALENT_APP_DATA: "FETCH_CONTENTFUL_TALENT_APP_DATA",
  SET_POOL_POSITION_DATA: "SET_POOL_POSITION_DATA",
  SET_TEAM_DATA: "SET_TEAM_DATA",
  SET_REQUIRED_ASSESSMENTS: "SET_REQUIRED_ASSESSMENTS",
  SET_POOL_REQUIRED_ASSESSMENTS: "SET_POOL_REQUIRED_ASSESSMENTS",
  SET_USER_DATA: "SET_USER_DATA",
  SET_USER_ASSESSMENT_REPORTS_AND_DATA:"SET_USER_ASSESSMENT_REPORTS_AND_DATA",
  FETCH_CONTENTFUL_ANALYTICS_DATA: "FETCH_CONTENTFUL_ANALYTICS_DATA",
};
