import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "../../store/actions/userDataActions";
import TalentsApiService from "../../TalentsApiService";
import * as Utilities from "../../Utilities";
import ChangePassword from "./ChangePassword";

import Form from "../../components/reusableElementsComponents/Form";
import Button from "../../components/reusableElementsComponents/Button";
import Loading from "../../components/reusableElementsComponents/Loading";
import Loader from "../../components/reusableElementsComponents/Loader";
import PageLayout from "../../components/layoutComponents/PageLayout";

import person from "../../assets/images/icons/person.svg";
import letter from "../../assets/images/icons/letter.svg";
import editIcon from "../../assets/images/icons/editIcon.png";
import cog from "../../assets/images/icons/cog.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { ToastContainer, toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

// This content placeholder is to prevent the app from crashing if the content is not fetched from Contentful
const content_placeholder = {
  titleDesktop: "Settings",
  generalSettings: "General",
  changePassword: "Change Password",
  firstName: "First name",
  lastName: "Last name",
  email: "Email address",
  inputPlaceholder: "Start typing...",
  saveChanges: "Save changes",
  deleteAccount:
    "If you wish to delete or deactivate your account, send us a request in Help Center by filling out the form, or contacting us via email info@mazhr.com. Please note that after deletion, all your data and information will be lost and cannot be recovered.",
  toastMessageUpdate: "Updating details...",
  toastMessageSuccess: "Setting details saved",
  toastMessageError: "Unable to save user data",
};

function Settings() {
  const pageData = useSelector(
    (state) => state.contentfulData?.all?.["settings"]
  );
  const initialUserInfo = useSelector((state) => state.userData?.all?.["info"]);
  const [userInfo, setUserInfo] = useState({});
  const [template, setTemplate] = useState({});
  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const talentsServiceObj = useMemo(() => {
    return new TalentsApiService();
  }, []);

  useEffect(() => {
    if (initialUserInfo?.first) {
      setUserInfo(initialUserInfo);
    } else {
      talentsServiceObj.GetCurrentUserDetails((json) => {
        setUserInfo(json);
        dispatch(setUserData({ all: json }));
      });
    }
  }, [initialUserInfo, talentsServiceObj, dispatch]);

  const onSubmit = (values) => {
    debugger;
    setLoader(true);
    const savingDetails = toast.loading(
      pageData?.fields?.toastMessageUpdate ||
        content_placeholder.toastMessageUpdate
    );
    talentsServiceObj
      .SaveUserData({
        first: values.firstname.length > 0 ? values.firstname : userInfo.first,
        last: values.lastname.length > 0 ? values.lastname : userInfo.last,
        email:
          values.emailaddress.length > 0 ? values.emailaddress : userInfo.email,
      })
      .then((res) => {
        if (res.httpStatus === 200) {
          toast.update(savingDetails, {
            render:
              pageData?.fields?.toastMessageSuccess ||
              content_placeholder.toastMessageSuccess,
            type: "success",
            isLoading: false,
            autoClose: "2000",
            closeButton: true,
            hideProgressBar: true,
          });
          setLoader(false);
          window.scrollTo(0, 0);
        } else {
          toast.update(savingDetails, {
            render:
              pageData?.fields?.toastMessageError ||
              content_placeholder.toastMessageError,
            type: "error",
            isLoading: false,
            autoClose: "2000",
            closeButton: true,
            hideProgressBar: true,
          });
          setLoader(false);
          window.scrollTo(0, 0);
        }
      });
  };

  useEffect(() => {
    if (
      !Utilities.isEmptyObject(pageData?.fields) &&
      !Utilities.isEmptyObject(userInfo)
    ) {
      let content = pageData?.fields;
      let firstName, lastName, email, inputPlaceholder;
      if (content) {
        firstName = content.firstName || content_placeholder.firstName;
        lastName = content.lastName || content_placeholder.lastName;
        email = content.email || content_placeholder.email;
        inputPlaceholder =
          content.inputPlaceholder || content_placeholder.inputPlaceholder;

        setTemplate({
          fields: [
            {
              title: firstName,
              type: "text",
              name: firstName,
              placeholder: inputPlaceholder,
              icon: person,
              defaultValue: userInfo?.first,
            },
            {
              title: lastName,
              type: "text",
              name: lastName,
              placeholder: inputPlaceholder,
              icon: person,
              defaultValue: userInfo?.last,
            },
            {
              title: email,
              type: "email",
              name: email,
              placeholder: inputPlaceholder,
              defaultValue: userInfo?.email,
              icon: letter,
            },
          ],
        });
      }
    } else {
      <Loading />;
    }
  }, [userInfo, pageData]);

  return (
    <>
      <div className="bg-cover">
        <PageLayout>
          <div className="section-wrapper">
            <h1>
              {pageData?.fields?.titleDesktop ||
                content_placeholder.titleDesktop}
            </h1>
          </div>
        </PageLayout>
        <div className="settings-page-layout-wrapper">
          <div className="inner-layout">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Col sm={12} className="d-flex vp-1200">
                <div>
                  <Nav
                    variant="pills"
                    className="flex-column bg-white py-3 px-2 mb-3 rounded"
                    style={{ width: "300px" }}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        {" "}
                        <img className="m-2" src={cog} alt="settings" />
                        {pageData?.fields?.generalSettings ||
                          content_placeholder.generalSettings}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        {" "}
                        <img
                          height="20px"
                          className="m-2"
                          src={editIcon}
                          alt="change-password"
                        />{" "}
                        {pageData?.fields?.changePassword ||
                          content_placeholder.changePassword}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Tab.Content className="w-100">
                  <Tab.Pane eventKey="first">
                    <div className="settings-page">
                      <div className="settings-form">
                        {userInfo.first &&
                        !Utilities.isEmptyObject(template) ? (
                          <Form
                            template={template}
                            watchFields={["first", "last"]}
                            onSubmit={onSubmit}
                          >
                            {loader ? (
                              <Button btnstyle={"mazhrBtn light-grey"}>
                                <Loader />
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                btnstyle={"mazhrBtn default-green white"}
                                onSubmit={onSubmit}
                              >
                                {pageData?.fields?.saveChanges ||
                                  content_placeholder.saveChanges}
                              </Button>
                            )}
                            <div className="flex mar-20 top">
                              <InfoOutlinedIcon
                                className="mar-15 right"
                                style={{
                                  fontSize: "2em",
                                }}
                              />
                              <p>
                                {pageData?.fields?.deleteAccount ||
                                  content_placeholder.deleteAccount}
                              </p>
                            </div>
                          </Form>
                        ) : (
                          <Loading
                            sectionLoading={true}
                            containerHeight={"400px"}
                          />
                        )}
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <ChangePassword />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Tab.Container>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}

export default Settings;
