import React, { useEffect, useState } from "react";
import * as Constants from "../../constants";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useSelector } from "react-redux";
import * as Utilities from "../../Utilities";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const RadarChartInfo = ({
  title,
  tabActiveKey,
  radarLabel,
  radarLabelDesc,
  accordionArray,
}) => {
  let contentfulDataIndex = "";
  if (tabActiveKey === Constants.TAB_TEAM_STRENGTHS_KEY) {
    contentfulDataIndex = "team strengths";
  } else if (tabActiveKey === Constants.TAB_SALES_STRENGTHS_KEY) {
    contentfulDataIndex = "sales strengths";
  } else if (tabActiveKey === Constants.TAB_ADEPT_ASPECTS_KEY) {
    contentfulDataIndex = "adept-15";
  }

  const [value, setValue] = useState(0);
  const [metrics, setMetrics] = useState([]);
  const [subValues, setSubValues] = useState([]);

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ pt: 0, pr: 3, pb: 3, pl: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    if (metrics[newValue]) {
      setValue(newValue);
    }
  };

  const userData = useSelector(
      (state) => state.userData?.all
  );

  const contentfulData = useSelector(
    (state) => state.contentfulData.all[contentfulDataIndex]?.fields
  );

  useEffect(() => {
    if (contentfulData) {
      const keys = Object.keys(contentfulData).map((key) =>
        Utilities.camelCaseToNormal(key)
      );
      if (tabActiveKey === Constants.TAB_ADEPT_ASPECTS_KEY){
        let adeptWorkStyles = [
          "Task",
          "Adaptation",
          "Achievement",
          "Teamwork",
          "Emotional",
          "Interaction",
        ]

        const combined = Constants.ADEPT_ASPECTS.reduce((obj, key, index) => {
          obj[key] = userData?.adept_aspects_points[index];
          return obj;
        }, {});

        setSubValues(combined)
        setMetrics(adeptWorkStyles);
      }else{
        setMetrics(keys.slice(1));
      }

    }
  }, [tabActiveKey, contentfulData, userData]);

  return (
    <div>
      <div className='radar-chart-info-component'>
        <div className="title" style={{ margin: "10px 10px 10px 0" }}>
          <p className="m-0">{title}</p>
        </div>
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              width: 250,
              minWidth: 150,
            }}
          >
            {metrics.map(
              (labelName, index) =>
                Utilities.normalToCamelCase(labelName) !==
                  "scaleDescription" && (
                  <Tab label={labelName} key={index} {...a11yProps(index)} />
                )
            )}
          </Tabs>
          {metrics.map((labelName, index) => (
            <TabPanel value={value} index={index} key={index}>
              {contentfulData &&
              tabActiveKey !== Constants.TAB_COMPETENCIES_KEY &&
              tabActiveKey !== Constants.TAB_MOTIVATIONS_KEY &&
              tabActiveKey !== Constants.TAB_ADEPT_ASPECTS_KEY? (
                <>
                  {/* High and Low Scales Content */}
                  <h5 className="grey">
                    {contentfulData[Utilities.normalToCamelCase(labelName)]}
                  </h5>
                  <br />
                  <div>
                    <p className="header-font">
                      When {labelName}{" "}
                      <span style={{ color: "#000000", fontWeight: "700" }}>
                        is above Average
                      </span>
                    </p>
                    <ul className="list-margin">
                      {contentfulData?.scaleDescription[
                        // Quick fix for the label name mismatch between contentful and API, also in corporate app
                        labelName === "Preparing a solution"
                          ? "preparing A Solution"
                          : Utilities.normalToCamelCase(labelName)
                      ]?.high?.map((scale, index) => (
                          <li className="list-font" key={index}>
                            <span className="checkmark">&#x2713;</span>{" "}
                            <span>{scale}</span>
                          </li>
                      ))}
                    </ul>
                    <p className="header-font">
                      If {labelName}{" "}
                      <span style={{ color: "#000000", fontWeight: "700" }}>
                        is below Average
                      </span>
                    </p>
                    <ul className="list-margin">
                      {contentfulData?.scaleDescription[
                          // Quick fix for the label name mismatch between contentful and API, also in corporate app
                          labelName === "Preparing a solution"
                              ? "preparing A Solution"
                              : Utilities.normalToCamelCase(labelName)
                          ]?.low?.map((scale, index) => (
                        <li className="list-font" key={index}>
                          <span className="crossmark">&#x2715;</span>{" "}
                          <span>{scale}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              ) : tabActiveKey === Constants.TAB_ADEPT_ASPECTS_KEY ? (
                  <>
                    {/* High and Low Scales Content */}
                    <h5 className="grey">
                      {contentfulData.definitions?.styles?.[Utilities.normalToCamelCase(labelName) + "_style"]}
                    </h5>
                    <br />
                    <Accordion activeIndex={0}>
                    {Constants.ADEPT_STYLES[Utilities.normalToCamelCase(labelName)].map((aspect, index) => (
                        <AccordionTab header={`${aspect} ( ${subValues[aspect]} )`}>
                        <div>
                          <p className="header-font">
                            When {aspect}{" "}
                            <span style={{color: "#000000", fontWeight: "700"}}>
                        is above Average
                      </span>
                          </p>
                          <ul className="list-margin">
                            {contentfulData?.scaleDescription[aspect]?.high?.map((scale, index) => (
                                <li className="list-font" key={index}>
                                  <span className="checkmark">&#x2713;</span>{" "}
                                  <span>{scale}</span>
                                </li>
                            ))}
                          </ul>
                          <p className="header-font">
                            If {aspect}{" "}
                            <span style={{color: "#000000", fontWeight: "700"}}>
                        is below Average
                      </span>
                          </p>
                          <ul className="list-margin">
                            {contentfulData?.scaleDescription[aspect]?.low?.map((scale, index) => (
                                <li className="list-font" key={index}>
                                  <span className="crossmark">&#x2715;</span>{" "}
                                  <span>{scale}</span>
                                </li>
                            ))}
                          </ul>
                        </div>
                      </AccordionTab>
                    ))}
                    </Accordion>
                  </>
              ) : (
                  <>
                    {/* Accordion Content */}
                    <div className="label-dialog-content">
                      <div style={{marginBottom: "10px"}}>
                        <div
                            style={{marginBottom: "20px", textAlign: "center"}}
                        >
                        <span
                            style={{
                              fontStyle: "normal",
                              fontWeight: "bold",
                              fontSize: "18px",
                            }}
                        >
                          {radarLabel}
                        </span>
                        </div>
                        <div style={{margin: "0 10px 15px 10px"}}>
                        <span style={{fontStyle: "italic"}}>
                          {radarLabelDesc}
                        </span>
                        </div>
                        <Accordion>
                          {accordionArray?.map((item, index) => (
                              <AccordionTab
                                  key={index}
                                  header={item.title}
                                  headerClassName="accordian-header-class"
                              >
                                <ul>
                                  {item.content.map((contentItem, contentIndex) => (
                                      <li
                                  className="list-style-disc"
                                  key={contentIndex}
                                >
                                  {contentItem}
                                </li>
                              ))}
                            </ul>
                          </AccordionTab>
                        ))}
                      </Accordion>
                    </div>
                  </div>
                </>
              )}
            </TabPanel>
          ))}
        </Box>
      </div>
    </div>
  );
};

export default RadarChartInfo;
