import React, { useState, useEffect } from 'react';
import StrengthsRadarChart from '../reusableElementsComponents/StrengthsRadarChart';
import RadarChartInfo from '../talentsComponents/RadarChartInfo';

const TeamStrengthsSection = ({ data, copy }) => {
    const [teamsChartData, setTeamsChartData] = useState({
        labels: copy?.teamStrengthsLabels,
        datasets: [
            {
                label: "Team Strengths",
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: "rgba(255,99,132,1)",
                pointBackgroundColor: "rgba(255,99,132,1)",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "rgba(255,99,132,1)",
                data: data.team_strengths,
            },
        ],
    });

    useEffect(() => {
        setTeamsChartData({
            labels: copy?.teamStrengthsLabels,
            datasets: [
                {
                    label: "Team Strengths",
                    backgroundColor: "rgba(255,99,132,0.2)",
                    borderColor: "rgba(255,99,132,1)",
                    pointBackgroundColor: "rgba(255,99,132,1)",
                    pointBorderColor: "#fff",
                    pointHoverBackgroundColor: "#fff",
                    pointHoverBorderColor: "rgba(255,99,132,1)",
                    data: data.team_strengths,
                },
            ],
        });
    }, [data.team_strengths, copy?.teamStrengthsLabels]);

    const lightOptionsTeams = {
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    color: "#495057",
                },
                position: "bottom",
            },
        },
        scales: {
            r: {
                min: 0,
                max: 9,
                ticks: {
                    stepSize: 1,
                },
                pointLabels: {
                    fontSize: 14,
                    color: "#495057",
                    padding: 0,
                    margin: 0,
                },
                grid: {
                    color: "#ebedef",
                },
                angleLines: {
                    color: "#ebedef",
                },
            },
        },
    };

    return (
        <div className="row chart-with-info-section">
            <div className="col-6 vertical-divider">
                <div className="talent-details-strengths-charts">
                    <StrengthsRadarChart
                        title="Team Strengths"
                        data={teamsChartData}
                        options={lightOptionsTeams}
                    />
                </div>
            </div>

            <div className="col-6">
                <div className="motivation" id="boxStyleMot">
                    <RadarChartInfo
                        title="Team Strengths Info"
                        tabActiveKey={"4"} // Make sure to pass the correct active tab key
                        radarLabel={"Preparing The Ground"} // Make sure to pass the correct radar label
                    />
                </div>
            </div>
        </div>
    );
};

export default TeamStrengthsSection;
