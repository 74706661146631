//import React, {useState, useEffect} from "react";
//import ProgressBar from "react-bootstrap/ProgressBar";
import TargetPositionComposition from "./TargetPositionComposition";
import CandidateCompetences from "./CandidateCompetences";
import CandidateMotivationFactors from "./CandidateMotivationFactors";
import AdeptAspectsSection from '../TalentMatchScoreComponents/AdeptAspectsSection';
import SalesStrengthsSection from '../TalentMatchScoreComponents/SalesStrengthsSection';
import TeamStrengthsSection from '../TalentMatchScoreComponents/TeamStrengthsSection';
import ScoreLine from "../reusableElementsComponents/ScoreLine";
import ExplanationTooltip from "../reusableElementsComponents/ExplanationTooltip";
import { Accordion } from "react-bootstrap";
import {getAONAssessmentLabelById} from '../../Utilities'
import "react-circular-progressbar/dist/styles.css";
import { useSelector } from "react-redux";

function TalentMatchScore({ candidateData, matchPercentage }) {
  const data = candidateData;

  const copy = useSelector(
    (state) => state.contentfulData.all["talent-data"]?.fields
  );

  // FOR PROFILES
  /*const [compatibilityVisible, setCompatibilityVisible] = useState(3);

  const showMoreCompatibility = () => {
    setCompatibilityVisible(data.target_profile_scores?.length);

    document
      .getElementById("showMoreCompatibility")
      .classList.remove("show", "flex");
    document.getElementById("showMoreCompatibility").classList.add("hide");
    document
      .getElementById("showLessCompatibility")
      .classList.add("show", "flex");
  };

  const showLessCompatibility = () => {
    setCompatibilityVisible(3);
    document
      .getElementById("showLessCompatibility")
      .classList.remove("show", "flex");
    document.getElementById("showLessCompatibility").classList.add("hide");
    document
      .getElementById("showMoreCompatibility")
      .classList.add("show", "flex");
  };

  const getProgressValue = (score) => {
    switch (score) {
      case 9:
        return 100;
      case 8:
        return 90;
      case 7:
        return 80;
      case 6:
        return 70;
      case 5:
        return 55;
      case 4:
        return 45;
      case 3:
        return 35;
      case 2:
        return 25;
      case 1:
        return 10;
      default:
        return 0;
    }
  };*/

  return (
    <>
      {data && (
        <div className="match-section">
          {data.target_position_competencies_distribution &&
            data.target_position_competencies_distribution.length > 0 && (
              <div className="white-box">
                <TargetPositionComposition
                  data={data}
                  matchPercentage={matchPercentage}
                />
              </div>
            )}

          {/*TEAM STRENGTH SECTION*/}
          {data?.team_strengths.length === 9 && <TeamStrengthsSection data={data} copy={copy} />}

          {/*ADEPT ASPECTS SECTION*/}
          {data?.adept_aspects_points?.length === 15 && <AdeptAspectsSection data={data} copy={copy} />}

          {/*COMPETENCE & MOTIVATIONS SECTION*/}
          <div className="row">
            {/*COMPETENCE SECTION*/}
            <div className="col-6">
              <div className="competences-motivation-section">
                <CandidateCompetences data={data} />
              </div>
            </div>

            {/*MOTIVATIONS SECTION*/}
            <div className="col-6">
              <div className="competences-motivation-section">
                <CandidateMotivationFactors data={data} />
              </div>
            </div>
          </div>

          {/*SALES STRENGTH SECTION*/}
          {data?.sales_strengths?.competence?.length === 8 && <SalesStrengthsSection data={data} copy={copy} />}

          <div className="row">
            <div className="col-6" style={{marginBottom: "20px"}}>
              {Object.keys(candidateData?.skill_assessment).length !== 0 && (
                  <div className="assessments-section" id="boxStyleAssessment">
                    <div className="title">
                      <p className="m-0">
                        Reasoning and Skills
                        <ExplanationTooltip>
                          {/* {contentfulDate.targetProfileHelpLink} */}
                        </ExplanationTooltip>
                      </p>
                      <p className="xs grey">
                        Verified by AON online assessments
                      </p>
                    </div>
                    <div className="stats">
                      <Accordion>
                        {Object.entries(candidateData?.skill_assessment).map(([id, score], index) => (
                            <Accordion.Item eventKey={id} key={id}>
                              <Accordion.Header>
                                <div className="accordion-header-wrapper">
                                  <p className="mar-5 right s">{getAONAssessmentLabelById(parseInt(id))}</p>
                                  {index === 0 ? (
                                      <div style={{display: "flex", flexDirection: "column"}}>
                                        <div className="scoreline-div">
                                          <span className="grey-bar">Low</span>
                                          <span className="grey-bar">Average</span>
                                          <span className="grey-bar">High</span>
                                        </div>
                                        <ScoreLine val={Math.round((score / 100) * 10)}/>
                                      </div>
                                  ) : (
                                      <ScoreLine val={Math.round((score / 100) * 10)}/>
                                  )}
                                </div>
                              </Accordion.Header>
                            </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  </div>
              )}
            </div>
            <div className="col-6">
              {data.assessment_report_list?.length > 0 && (
                  <div
                      className=""
                      id="boxStyleReports"
                      style={{marginTop: "15px"}}
                  >
                    <div className="title">
                      <p className="m-0">
                        {copy?.downloadableReportsSectionTitle}
                      </p>
                    </div>
                    <div className="container">
                      {data?.assessment_report_list?.map(
                          (assessment_report, index) =>
                              assessment_report?.score_url &&
                              copy?.assessmentReportNames[
                                  assessment_report?.assessment_name
                                  ] ? (
                                  <div className="image-container" key={index}>
                                    <a
                                        href={assessment_report?.score_url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                      {/* Add any image or content you want to display */}
                                    </a>
                                    <h2>
                                      {
                                        copy?.assessmentReportNames[
                                            assessment_report?.assessment_name
                                            ]
                                      }
                                    </h2>
                                  </div>
                              ) : null
                      )}
                    </div>
                  </div>
              )}
            </div>
          </div>

          {/*<div className="row">
            COMPETENCE SECTION
            <div className="col-12">
              <div
                className="compatibility-section"
                id="highestCompatibilityBox"
                style={{
                  flexGrow: candidateData.skill_assessment.length === 0 ? 1 : 0,
                  flexBasis:
                    candidateData.skill_assessment.length !== 0 ? "50%" : "0%",
                }}
              >
                <div className="title">
                  <p className="m-0">
                    {copy?.highestCompatabilitySectionTitle}
                  </p>
                  <p className="xs grey">Verified by AON online assessments</p>
                </div>
                <div className="accordion-main">
                  {data.target_profile_scores?.length > 0 ? (
                    data.target_profile_scores
                      ?.sort(function (a, b) {
                        return b.score - a.score;
                      })
                      .slice(0, compatibilityVisible)
                      .map((score, index) =>
                        copy?.highestCompatibility?.map((scoreCopy) => {
                          if (score.profile === scoreCopy?.code) {
                            return (
                              <div className="stat" key={index}>
                                <div className="description">
                                  <p className="title m-0 s">
                                    {scoreCopy?.title}
                                  </p>
                                  <p className="score m-0">
                                    {score.score === 9 && (
                                      <span className="bold">100</span>
                                    )}
                                    {score.score === 8 && (
                                      <span className="bold">90</span>
                                    )}
                                    {score.score === 7 && (
                                      <span className="bold">80</span>
                                    )}
                                    {score.score === 6 && (
                                      <span className="bold">70</span>
                                    )}
                                    {score.score === 5 && (
                                      <span className="bold">55</span>
                                    )}
                                    {score.score === 4 && (
                                      <span className="bold">45</span>
                                    )}
                                    {score.score === 3 && (
                                      <span className="bold">35</span>
                                    )}
                                    {score.score === 2 && (
                                      <span className="bold">25</span>
                                    )}
                                    {score.score === 1 && (
                                      <span className="bold">10</span>
                                    )}
                                    /100
                                  </p>
                                </div>
                                <div className="progress-bar-wrapper">
                                  <ProgressBar
                                    now={getProgressValue(score.score)}
                                  />
                                </div>
                              </div>
                            );
                          } else return "";
                        })
                      )
                  ) : (
                    <div className="sendToTests">
                      <p>No compatibility at the moment</p>
                    </div>
                  )}
                </div>
                {data.target_profile_scores?.length > 0 && (
                  <>
                    <button
                      id="showMoreCompatibility"
                      className="show-more-link link"
                      onClick={showMoreCompatibility}
                    >
                      <p className="xs bold">Show more</p>
                    </button>
                    <button
                      id="showLessCompatibility"
                      className="show-more-link link hide"
                      onClick={showLessCompatibility}
                    >
                      <p className="xs bold">Show less</p>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>*/}
        </div>
      )}
    </>
  );
}

export default TalentMatchScore;
