import React, {useRef, useEffect, useState, useCallback} from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Chart from 'chart.js/auto';
import {useSelector} from 'react-redux';
import {Box, Button, MenuItem, Menu} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import TalentsApiService from '../../TalentsApiService';
import {Checkbox} from 'primereact/checkbox';
import loading from '../../assets/images/icons/loading.gif';
import * as Constants from '../../constants';

const FileDownload = ({tabActiveKey, columnLabels, downloadData, selectedTeams}) => {
    const contentfulData = useSelector(state => state.contentfulData.all['sales strengths']?.fields);
    let b2Obj = new TalentsApiService();
    let [count, setCount] = useState(0)
    let OptionKey = ""
    const [chartInstance, setChartInstance] = useState(null);
    // eslint-disable-next-line
    const [arrayVal, setArrayVal] = useState([]);
    // eslint-disable-next-line
    const [pdfCheck, setPdfCheck] = useState(true);
    // eslint-disable-next-line
    const [excelCheck, setExcelCheck] = useState(false);
    // eslint-disable-next-line
    let [colList, setcolList] = useState([]);
    const [columns, setColumns] = useState([]);
    const [dataTableData, setDataTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [reportTitle, setReportTitle] = useState("");
    const [loader, setLoader] = useState(false);
    const categories = [{name: 'PDF', key: 'pdf'}, {name: 'Excel', key: 'excel'}];
    const [selectedCategories, setSelectedCategories] = useState(categories.slice(0, 1));

    const selectOptions = [
        {title: "All", optionKey: Constants.DOWNLOAD_OPTIONS_FOR_ALL},
        {title: "Competency", optionKey: Constants.TAB_COMPETENCIES_KEY},
        {title: "Motivation", optionKey: Constants.TAB_MOTIVATIONS_KEY},
        {title: "Sales Strength", optionKey: Constants.TAB_SALES_STRENGTHS_KEY},
        {title: "Team Strength", optionKey: Constants.TAB_TEAM_STRENGTHS_KEY}
    ]

    useEffect(() => {
        if (tableColumns.length && tableColumns[0].columns_list) {
            let newCol = tableColumns.find(x => x.group_name === "All").columns_list;
            setColumns(newCol)
        } else {
            setColumns(tableColumns)
        }
        // eslint-disable-next-line
    }, [tableColumns])

    useEffect(() => {
        if (columns.length) {
            generateUnselectedReports()
        }
        // eslint-disable-next-line
    }, [columns])

    useEffect(() => {
        if (tabActiveKey === Constants.TAB_COMPETENCIES_KEY) {
            setReportTitle("Competencies")
        } else if (tabActiveKey === Constants.TAB_MOTIVATIONS_KEY) {
            setReportTitle("Motivations")
        } else if (tabActiveKey === Constants.TAB_SALES_STRENGTHS_KEY) {
            setReportTitle("Sales Strengths")
        } else if (tabActiveKey === Constants.TAB_TEAM_STRENGTHS_KEY) {
            setReportTitle("Team Strengths")
        }
    }, [tabActiveKey])


    const onCategoryChange = (e) => {
        let _selectedCategories = [...selectedCategories];

        if (e.checked) {
            _selectedCategories.push(e.value);
            if (e.value.name === "Excel") {
                setExcelCheck(true)
            } else if (e.value.name === "PDF") {
                setPdfCheck(true)
            }
        } else {
            for (let i = 0; i < _selectedCategories.length; i++) {
                const selectedCategory = _selectedCategories[i];

                if (selectedCategory.key === e.value.key) {
                    if (_selectedCategories[i].name === "Excel") {
                        setExcelCheck(false)
                    }
                    if (_selectedCategories[i].name === "PDF") {
                        setPdfCheck(false)
                    }
                    _selectedCategories.splice(i, 1);
                    break;
                }
            }
        }

        setSelectedCategories(_selectedCategories);
    }

    const getCompetencePointsPdf = async () => {
        // eslint-disable-next-line
        const competencePointsData = await getCompetencePoints();
        setTimeout(() => {
            count = count + 1
            setCount(count)
            getMotivationStrengthPdf()
        }, 5000);
    }

    const getMotivationStrengthPdf = async () => {
        // eslint-disable-next-line
        const motivationStrengthData = await getMotivationStrength();
        setTimeout(() => {
            count = count + 1
            setCount(count)
            getTeamStrengthPdf()
        }, 5000);
    }

    const getTeamStrengthPdf = async () => {

        // eslint-disable-next-line
        const teamStrengthData = await getTeamStrength();
        setTimeout(() => {
            count = count + 1
            setCount(count)
            setSelectedOption("Sales Strength")
            getSalesDataPdf()
        }, 5000);

    }

    const getSalesDataPdf = async () => {
        // eslint-disable-next-line
        const salesData = await getSalesDataFunction();
    }

    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    const canvasRef = useRef(null);
    const handleChange = async (val) => {
        setSelectedOption(val)
        selectOptions.map((obj) => {
            if (val === obj.title) {
                OptionKey = obj.optionKey
            }
            return true
        })
        if (tabActiveKey === OptionKey) {
            if (downloadData.length !== 0) {
                generateReport()
            } else if (OptionKey === Constants.DOWNLOAD_OPTIONS_FOR_ALL) {
                if (pdfCheck || excelCheck) {
                    count = count + 1
                    setCount(count)
                    await getCompetencePointsPdf();
                }
            }
        } else if (tabActiveKey !== OptionKey) {
            if (OptionKey === Constants.DOWNLOAD_OPTIONS_FOR_ALL) {
                if (pdfCheck || excelCheck) {
                    count = count + 1
                    setCount(count)
                    await getCompetencePointsPdf();
                }
            } else {
                if (OptionKey === Constants.TAB_COMPETENCIES_KEY) {
                    getCompetencePoints();
                } else if (OptionKey === Constants.TAB_MOTIVATIONS_KEY) {
                    getMotivationStrength();
                } else if (OptionKey === Constants.TAB_SALES_STRENGTHS_KEY) {
                    getSalesDataFunction()
                } else if (OptionKey === Constants.TAB_TEAM_STRENGTHS_KEY) {
                    getTeamStrength();
                }
            }

        }
    }

    const getCompetencePoints = async () => {
        setReportTitle("Competencies")
        let hash_list = [];
        if (selectedTeams.length > 0) {
            hash_list = selectedTeams.map(x => x.hash);
        }
        b2Obj.GetCompetencePointsList(
            {
                company_id: "",
                hash_list: hash_list,
                is_user_pool: ""
            }, [], setDataTableData, setTableColumns, setLoader, setArrayVal)
    }

    const getMotivationStrength = async () => {
        setReportTitle("Motivations")
        let hash_list = [];

        if (selectedTeams.length > 0) {
            hash_list = selectedTeams.map(x => x.hash);
        }
        b2Obj.GetMotivationStrengthList(
            {
                company_id: "",
                hash_list: hash_list,
                is_user_pool: ""
            }, [], setDataTableData, setTableColumns, setLoader, setArrayVal);
    }

    const getSalesDataFunction = () => {
        setReportTitle("Sales Strengths")
        let hash_list = [];
        if (selectedTeams.length > 0) {
            hash_list = selectedTeams.map(x => x.hash);
        }
        b2Obj.getSalesData(
            {
                hash_list: hash_list,
                is_pool_list: ""

            }, setTableColumns, setDataTableData, setcolList, contentfulData, setLoader);
    }

    const getTeamStrength = async () => {
        setReportTitle("Team Strengths")
        let hash_list = [];

        if (selectedTeams.length > 0) {
            hash_list = selectedTeams.map(x => x.hash);
        }
        b2Obj.GetTeamStrengthList(
            {
                company_id: "",
                hash_list: hash_list,
                is_user_pool: ""
            }, [], setDataTableData, setTableColumns, setLoader);

    }

    const generateExcelFile = (tableData) => {
        const XLSX = require('xlsx');
        const sheetName = `${reportTitle}`;
        // Create a new workbook and add a worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(tableData);
        // Set the worksheet name and add it to the workbook
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        // Generate an Excel file and save it
        XLSX.writeFile(wb, `${reportTitle} Report.xlsx`);
    }

    const createCommonStructure = (userData, excludedProps, tableData, chartData) => {
        userData?.forEach((user, index) => {
            let randomColor
            const userObjectKeys = Object.keys(user);
            let finalObj = {};
            let chartPoints = [];
            userObjectKeys.forEach((key) => {
                if (!excludedProps.includes(key)) {
                    finalObj[`${key}`] = user[`${key}`];
                    if (typeof user[`${key}`] === "number" && key !== "user_id") {
                        chartPoints.push(user[`${key}`]);
                    }
                }
            });
            randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
            ;
            const chart = {
                datasets: [
                    {
                        label: user.first_name + " " + user.last_name,
                        data: chartPoints,
                        fill: true,
                        backgroundColor: randomColor + 40,
                        borderColor: randomColor,
                        pointBackgroundColor: randomColor,
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: randomColor,
                    },
                ],
            };
            tableData.push(finalObj);
            chartData.push(chart);
        });
    }

    const createSalesStructure = (userData, excludedProps, tableData, chartData) => {
        userData?.forEach((user, index) => {
            let randomColorAttitude
            let randomColorCompetence
            const userObjectKeys = Object.keys(user.attitude);
            userObjectKeys.splice(2, 0, 'name');
            let finalObjAttitude = {};
            let finalObjCompetency = {};
            let chartPointsAttitude = [];
            let chartPointsCompetency = [];
            userObjectKeys.forEach((key) => {
                if (!excludedProps.includes(key)) {
                    finalObjAttitude[`${key}`] = user.attitude[`${key}`];
                    if (typeof user.attitude[`${key}`] === "number" && key !== "user_id") {
                        chartPointsAttitude.push(user.attitude[`${key}`]);
                    }
                }
            });
            userObjectKeys.forEach((key) => {
                if (!excludedProps.includes(key)) {
                    if (key !== "user_name") {
                        finalObjCompetency[`${key}`] = user.competency[`${key}`];
                    } else {
                        finalObjCompetency[`${key}`] = "";
                    }

                    if (typeof user.competency[`${key}`] === "number" && key !== "user_id") {
                        chartPointsCompetency.push(user.competency[`${key}`]);
                    }
                }
            });
            randomColorAttitude = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
            ;
            randomColorCompetence = '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
            ;
            const chart = {
                datasets: [
                    {
                        // eslint-disable-next-line no-useless-concat
                        label: user.user_name + " " + "(Attitude)",
                        data: chartPointsAttitude,
                        fill: true,
                        backgroundColor: randomColorAttitude + 40,
                        borderColor: randomColorAttitude,
                        pointBackgroundColor: randomColorAttitude,
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: randomColorAttitude,
                    },
                    {
                        // eslint-disable-next-line no-useless-concat
                        label: user.user_name + " " + "(Competency)",
                        data: chartPointsCompetency,
                        fill: true,
                        backgroundColor: randomColorCompetence + 40,
                        borderColor: randomColorCompetence,
                        pointBackgroundColor: randomColorCompetence,
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: randomColorCompetence,
                    },
                ],
            };
            tableData.push(finalObjAttitude);
            tableData.push(finalObjCompetency);
            chartData.push(chart);
        });
    }

    const generatingSalesReports = (canvas, labels, tableData) => {
        if (pdfCheck || excelCheck) {
            setLoader(true)
            setTimeout(() => {
                let imageDataUrl = canvas.toDataURL("image/png");
                const documentDefinition = {
                    pageOrientation: 'landscape',
                    pageSize: {width: 1366, height: 768},
                    content: [
                        {
                            text: reportTitle,
                            fontSize: 16,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 0, 0, 10] // top, right, bottom, left
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: Array.from({length: 2 + labels.length}, () => parseInt('120', 10)),
                                body: [
                                    ['Name', 'Strength Type', ...labels],
                                    ...tableData.map((row) => Object.values(row)),
                                ],
                                alignment: 'center'
                            },
                        },
                        {
                            pageBreak: 'after',
                            text: "",
                            fontSize: 18,
                            bold: true,
                            margin: [0, 0, 40, 0]
                        },
                        {
                            text: 'Radar Chart',
                            alignment: "center",
                            fontSize: 18,
                            bold: true,
                        },
                        {
                            image: imageDataUrl,
                            width: 450,
                            height: 450,
                            alignment: 'center',
                        }
                    ],
                };
                if (pdfCheck) {
                    pdfMake.createPdf(documentDefinition).download(`${reportTitle} Report.pdf`);
                }
                if (excelCheck) {
                    generateExcelFile(tableData)
                }
                if (count === 4) {
                    count = 0
                    setCount(count)
                }
                setLoader(false)
            }, 3000)
        }
    }

    const generatingCommonReports = (canvas, labels, tableData) => {
        if (pdfCheck || excelCheck) {
            setLoader(true)
            setTimeout(() => {
                let imageDataUrl = canvas.toDataURL("image/png");
                const documentDefinition = {
                    pageOrientation: 'landscape',
                    pageSize: {width: 1366, height: 768},
                    content: [
                        {
                            text: reportTitle,
                            fontSize: 16,
                            bold: true,
                            alignment: 'center',
                            margin: [0, 0, 0, 10] // top, right, bottom, left
                        },
                        {
                            table: {
                                headerRows: 1,
                                widths: Array.from({length: 2 + labels.length}, () => parseInt('50', 10)),
                                body: [
                                    ['First Name', 'Last Name', ...labels],
                                    ...tableData.map((row) => Object.values(row)),
                                ],
                            },
                            alignment: 'center',
                        },
                        {
                            pageBreak: 'after',
                            text: "",
                            fontSize: 18,
                            bold: true,
                            margin: [0, 0, 40, 0]
                        },
                        {
                            text: 'Radar Chart',
                            alignment: "center",
                            fontSize: 18,
                            bold: true,
                        },
                        {
                            image: imageDataUrl,
                            width: 450,
                            height: 450,
                            alignment: 'center',
                        }
                    ],

                };

                if (pdfCheck) {
                    pdfMake.createPdf(documentDefinition).download(`${reportTitle} Report.pdf`);
                }
                if (excelCheck) {
                    generateExcelFile(tableData)
                }
                if (count === 4) {
                    count = 0
                    setCount(count)
                }
                setLoader(false)
            }, 3000)
        }
    }


    function generateReport() {
        const tableData = []
        const labels = [];
        const chartData = []
        let excludedProps = ['company_id', 'user_id', 'image', 'isSelected'];
        columnLabels?.forEach((col) => {
            labels.push(col.header_name);
        });
        if (tabActiveKey === Constants.TAB_SALES_STRENGTHS_KEY) {
            excludedProps = ['user_id', 'isSelected'];
            createSalesStructure(downloadData, excludedProps, tableData, chartData)
        } else {
            createCommonStructure(downloadData, excludedProps, tableData, chartData)
        }
        const array = chartData.flatMap((obj) => {
            return obj.datasets.map((dataset) => {
                return {...dataset};
            });
        });
        const data = {
            labels: labels,
            datasets: array
        };
        const chartOptions = {
            scale: {
                ticks: {
                    beginAtZero: true,
                },
                pointLabels: {
                    fontSize: 14,
                },
            },
        };
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        if (chartInstance !== null) {
            chartInstance.destroy();
        }
        const chart = new Chart(ctx, {
            type: 'radar',
            data: data,
            options: chartOptions,
        });
        setChartInstance(chart);
        if (tabActiveKey === Constants.TAB_SALES_STRENGTHS_KEY) {
            generatingSalesReports(canvas, labels, tableData)
        } else {
            generatingCommonReports(canvas, labels, tableData)
        }
    }

    function generateUnselectedReports() {
        const tableData = []
        const labels = [];
        const chartData = []
        let excludedProps = ['company_id', 'user_id', 'image', 'isSelected'];
        columns?.forEach((col) => {
            labels.push(col.header_name);
        });
        if (selectedOption === "Sales Strength") {
            excludedProps = ['user_id', 'isSelected'];
            createSalesStructure(dataTableData, excludedProps, tableData, chartData)
        } else {
            createCommonStructure(dataTableData, excludedProps, tableData, chartData)
        }
        const array = chartData.flatMap((obj) => {
            return obj.datasets.map((dataset) => {
                return {...dataset};
            });
        });
        const data = {
            labels: labels,
            datasets: array
        };
        const chartOptions = {
            scale: {
                ticks: {
                    beginAtZero: true,
                },
                pointLabels: {
                    fontSize: 14,
                },
            },
        };
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        if (chartInstance !== null) {
            chartInstance.destroy();
        }
        const chart = new Chart(ctx, {
            type: 'radar',
            data: data,
            options: chartOptions,
        });
        setChartInstance(chart);
        if (selectedOption === "Sales Strength") {
            generatingSalesReports(canvas, labels, tableData)
        } else {
            generatingCommonReports(canvas, labels, tableData)
        }
    }

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = useCallback((event) => {
        if (event && anchorEl
            && !anchorEl.contains(event.target)) {
            if(event.target){
                if(!event.target?.classList?.contains('p-checkbox-box')
                && !event.target?.classList?.contains('p-checkbox-icon')){
                    setAnchorEl(false);
                }
            }else{
                setAnchorEl(false);
            }

        }
    },[anchorEl]);

    useEffect(() => {
        document.addEventListener('click', handleClose);
        return () => {
            document.removeEventListener('click', handleClose);
        };
    }, [anchorEl, handleClose]);


    return (
        <div>
            <canvas style={{visibility: "hidden"}} ref={canvasRef} height={600} width={600}></canvas>
            {loader ? (
                count === 0 ? (
                    <div className="loader-button">
                        <span>Generating Report</span>
                        <img className="loader-img" src={loading} alt="Loading..."/>
                    </div>
                ) : count > 0 && count <= 4 ? (
                    <div className="loader-button">
                        <span>Generating Report {count} of 4</span>
                        <img className="loader-img" src={loading} alt="Loading..."/>
                    </div>
                ) : null
            ) : null}
            {(loader === false && count === 0) && (
                <div className="loader-button">
                    <Box width="250px" sx={{ fontFamily: "Raleway" }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            onClick={handleClick}
                            endIcon={<ArrowDropDownIcon />}
                            style={{"width": "250px"}}
                        >
                            Generate Report
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={(event) => handleClose(event)}
                            className='button-menu'
                            style={{"width": "250px"}}
                        >
                            {categories && (
                                <MenuItem  className='button-menu'>
                                    {categories.map((category) => {
                                        return (
                                            <div key={category.key} className="field-checkbox m-left">
                                                <Checkbox
                                                    inputId={category.key}
                                                    name="category"
                                                    value={category}
                                                    onChange={onCategoryChange}
                                                    checked={selectedCategories.some(
                                                        (item) => item.key === category.key
                                                    )}
                                                    disabled={category.key === "R"}
                                                />
                                                <label className="m-left" htmlFor={category.key}>
                                                    {category.name}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </MenuItem>
                            )}
                            {selectOptions &&
                            selectOptions.map((obj, index) => {
                                return (
                                    <MenuItem
                                        key={index}
                                        onClick={() => {
                                            handleChange(obj.title);
                                            handleClose();
                                        }}
                                    >
                                        {obj.title}
                                    </MenuItem>
                                );
                            })}
                        </Menu>
                    </Box>
                </div>
            )}
        </div>
    );
};
export default FileDownload;
