import React from "react";
import * as PropTypes from "prop-types";
import { Chart } from "primereact/chart";
import { useSelector } from "react-redux";

function StrengthsRadarChart(props) {
  const contentfulData = useSelector(
    (state) => state.contentfulData.all["talent-data"]?.fields
  );
  const MAX_WORDS_PER_LABEL = 1;
  const convertLabelsToArrayOfArrayOfLabels = (labelsArray) => {
    return labelsArray.map((label) => {
      if (typeof label == "string") {
        const words = label.split(" ");

        if (words.length <= MAX_WORDS_PER_LABEL) {
          return [label];
        }

        const splitLabels = [];

        splitLabels.push(words[words.length - 1]);

        // Reverse loop through the 'words' array starting from the second-to-last element
        for (let i = words.length - 2; i >= 0; i--) {
          if (words[i].length < 5) {
            // If the current word's length is less than 5, join it with the preceding word.
            splitLabels[0] = words[i] + " " + splitLabels[0];
          } else {
            // If the current word's length is greater than or equal to 5, add it as a new label at the beginning.
            splitLabels.unshift(words[i]);
          }
        }

        return splitLabels;
      } else {
        return label;
      }
    });
  };

  const DEFAULT_DATASET_PROPS = {
    pointBorderColor: "#fff",
    pointHoverBackgroundColor: "#fff",
  };

  if (props.data.datasets) {
    props.data.datasets.map((dataset) => {
      return { ...dataset, DEFAULT_DATASET_PROPS };
    });
  }

  if (props.data.labels) {
    props.data.labels = convertLabelsToArrayOfArrayOfLabels(props.data.labels);
  }

  return (
    <div className="chartDiv">
      <div className="title" style={{ margin: "0 10px -35px 0" }}>
        <p className="m-0">{props.title}</p>
        <p className="xs grey">{contentfulData?.verifiedByAon}</p>
      </div>
      <div className="justify-content-div">
        <Chart
          width="400px"
          height="450px"
          type="radar"
          data={props.data}
          options={props.options}
        />
      </div>
    </div>
  );
}

StrengthsRadarChart.propTypes = {
  title: PropTypes.any,
  data: PropTypes.shape({ datasets: PropTypes.any, labels: PropTypes.any }),
  options: PropTypes.shape({
    plugins: PropTypes.shape({
      legend: PropTypes.shape({
        position: PropTypes.string,
        labels: PropTypes.shape({ color: PropTypes.string }),
      }),
    }),
    scales: PropTypes.shape({
      r: PropTypes.shape({
        min: PropTypes.number,
        max: PropTypes.number,
        angleLines: PropTypes.shape({ color: PropTypes.string }),
        grid: PropTypes.shape({ color: PropTypes.string }),
        pointLabels: PropTypes.shape({
          padding: PropTypes.number,
          margin: PropTypes.number,
          color: PropTypes.string,
          fonfontSize: PropTypes.number,
        }),
      }),
    }),
  }),
};
export default StrengthsRadarChart;
